import {
  CustomRadio,
  Input,
  PaginatedTable,
  Select,
} from "../../../components/common";
import { Box, Button, HStack, Text, VStack } from "@chakra-ui/react";
import { useState } from "react";
import AsyncSelect from "react-select/async";
import { getDealershipEmails } from "../../../utils/api/dealership-setting.api";
import {
  editRing,
  createRing,
  getRingLines,
  addRingRouting,
  viewRing,
} from "../../../utils/api/line-rings.api";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import Loader from "../../common/Spinner";
import { useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const General = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { id } = useParams();
  const { ringId } = useParams();
  const { data, isFetching: isLoading } = useQuery({
    queryKey: ["ringdetail", ringId],
    queryFn: viewRing,
    refetchOnWindowFocus: false,
    retry: false,
    enabled: ringId !== undefined,
    onSuccess(data) {
      setRingData({
        ring_group: {
          name: data?.data?.name,
          description: data?.data?.description,
        },
        message_processing: data?.data?.message_processing ?? "",
        line_ids: data?.data?.lines.map((item: any) => {
          return {
            value: item.id,
            label: item.name,
          };
        }),
        ring_group_users_attributes: data?.data?.users.map((item: any) => {
          return {
            value: item.id,
            label: item.name,
          };
        }),
      });
    },
  });
  const [ringData, setRingData] = useState({
    ring_group: {
      name: data?.data?.name ?? "",
      description: data?.data?.description ?? "",
    },
    message_processing: data?.data?.message_processing ?? "",
    line_ids:
      data?.data?.lines.map((item: any) => {
        return {
          id: item.id,
          label: item.name, // Replace 'name' with 'label'
        };
      }) ?? [],
    ring_group_users_attributes:
      data?.data?.users.map((item: any) => {
        return {
          id: item.id,
          label: item.name, // Replace 'name' with 'label'
        };
      }) ?? [],
  });
  const mutation = useMutation(createRing);
  const editMutation = useMutation(editRing);
  const editLineMutation = useMutation(addRingRouting);
  const handleSubmit = () => {
    mutation.mutate(
      {
        dealership_id: id,
        ring_group: {
          name: ringData?.ring_group?.name,
          description: ringData?.ring_group?.description,
          dealership_id: id,
          message_processing: ringData?.message_processing,
        },
        line_ids: ringData?.line_ids?.map((item: any) => Number(item.value)),
        ring_group_users_attributes: ringData?.ring_group_users_attributes?.map(
          (item: any) => {
            return {
              user_id: item.value,
            };
          }
        ),
      },
      {
        onSuccess() {
          toast({
            description: "Ring group created successfully.",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
            onCloseComplete() {
           navigate(`/dealerships/${id}?tab=${3}&active=${1}`)}
          });
        },
        onError: (error: any) => {
          toast({
            description: error?.response?.data[0] ?? "Something went wrong",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };

  const handleEdit = async () => {
    const initialUserIds = data?.data?.users.map((user: any) => user.id) || [];
    const modifiedUserIds =
      ringData?.ring_group_users_attributes.map(
        (user: any) => user.id ?? user.value
      ) || [];
    const addedUserIds = modifiedUserIds.filter(
      (id: any) => !initialUserIds.includes(id)
    );
    const removedUserIds = initialUserIds.filter(
      (id: any) => !modifiedUserIds.includes(id)
    );

    // Check if there are changes in line IDs
    const initialLineIds = data?.data?.lines.map((user: any) => user.id) || [];
    const modifiedLineIds =
      ringData?.line_ids?.map((user: any) => user.id ?? user.value) || [];
    const addedLineIds = modifiedLineIds.filter(
      (id: any) => !initialLineIds.includes(id)
    );
    const removedLineIds = initialLineIds.filter(
      (id: any) => !modifiedLineIds.includes(id)
    );

    try {
      // If there are changes in line IDs, call editLineMutation first
      if (addedLineIds.length > 0 || removedLineIds.length > 0) {
        await editLineMutation.mutate({
          id: ringId,
          dealership_id: id,
          data: {
            lines: {
              remove_lines: removedLineIds,
              add_lines: addedLineIds,
            },
          },
        });
      }

      // Call editMutation
      await editMutation.mutate({
        id: ringId,
        data: {
          ring_group: {
            name: ringData?.ring_group?.name,
            description: ringData?.ring_group?.description,
            dealership_id: id,
            message_processing: ringData?.message_processing,
          },
          users: {
            add_users: addedUserIds,
            remove_users: removedUserIds,
          },
        },
      });

      // Handle success after both mutations
      toast({
        description: "Ring group updated successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top",
        // onCloseComplete() {
        //   navigate(`/dealerships/${id}`);
        // },
      });
    } catch (error: any) {
      // Handle errors
      toast({
        description: error?.response?.data[0] ?? "Something went wrong",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }
  };

  const loadEmails = async (inputValue: string) => {
    try {
      const response = await getDealershipEmails({
        queryKey: ["dealerUsers", id, inputValue],
      });
      const options = response?.data?.map((user: any) => ({
        value: user.id,
        label: user.name,
      }));
      return options;
    } catch (error) {
      console.error("Error fetching dealership data:", error);
      return [];
    }
  };
  const updateRingData = (key: any, value: any) => {
    setRingData((prevState) => {
      if (
        [
          "line_ids",
          "ring_group_users_attributes",
          "message_processing",
        ].includes(key)
      ) {
        return { ...prevState, [key]: value };
      }
      if (key in prevState.ring_group) {
        return {
          ...prevState,
          ring_group: {
            ...prevState.ring_group,
            [key]: value,
          },
        };
      }
      return prevState;
    });
  };
  const loadLines = async (inputValue: string) => {
    try {
      const response = await getRingLines({
        queryKey: ["ringLines", ringId ?? "", inputValue,id],
      });
      const options = response?.data
        ?.filter((item: any) => item.belongs_to_ring_group === false)
        ?.map((user: any) => ({
          value: user.id.toString(),
          label: user.name,
        }));
      return options;
    } catch (error) {
      console.error("Error fetching dealership data:", error);
      return [];
    }
  };
  const msgHandlingOptions = [
    {
      label: "Assign incoming text messages to the contact owner (Default)",
      value: "Assign incoming text messages to the contact owner (Default)",
    },
    {
      label:
        "Assign incoming text messages randomly between agents (if contact is not owned)",
      value:
        "Assign incoming text messages randomly between agents (if contact is not owned)",
    },
    {
      label: "Do not assign incoming text messages to agents",
      value: "Do not assign incoming text messages to agents",
    },
  ];
  return (
    <Box paddingTop="0.25rem" height="calc(100vh - 235px)" overflow="auto">
      <VStack
        gap="1.5rem"
        alignItems="flex-start"
        py="1.5rem"
        borderBottom="1px solid var(--grey-300)"
      >
        <HStack justifyContent="flex-start" w="100%" alignItems="flex-start">
          <Text textStyle="h5" fontWeight="700" w="25%">
            Add Name
          </Text>
          <VStack alignItems="flex-start" width="50%">
            <Input
              type="text"
              placeholder="Enter ring group name"
              maxW="30rem"
              value={ringData?.ring_group?.name}
              onChange={(e) => {
                updateRingData("name", e.target.value);
              }}
            />
            <Text textStyle="h6" color="var(--grey-700)" maxW="39rem">
              Use a name that is short and easily identifiable in your reports.
            </Text>
          </VStack>
        </HStack>
      </VStack>
      <VStack
        gap="1.5rem"
        alignItems="flex-start"
        py="1.5rem"
        borderBottom="1px solid var(--grey-300)"
      >
        <HStack justifyContent="flex-start" w="100%" alignItems="flex-start">
          <Text textStyle="h5" fontWeight="700" w="25%">
            Add Description:
          </Text>
          <VStack alignItems="flex-start" width="50%">
            <Input
              type="text"
              placeholder="Type description (Optional)"
              isTextarea={true}
              maxW="30rem"
              value={ringData?.ring_group?.description}
              onChange={(e) => {
                updateRingData("description", e.target.value);
              }}
            />
          </VStack>
        </HStack>
      </VStack>
      <VStack
        gap="1.5rem"
        alignItems="flex-start"
        py="1.5rem"
        borderBottom="1px solid var(--grey-300)"
      >
        <HStack
          justifyContent="flex-start"
          w="100%"
          alignItems="flex-start"
          position="relative"
        >
          <Text textStyle="h5" fontWeight="700" w="25%">
            Add Lines
          </Text>
          <VStack w="50%" maxW="30rem" alignItems="flex-start">
            <VStack alignItems="flex-start">
              {/* <Text textStyle="h5" fontWeight="700">
              Add Lines
              </Text> */}
              {/* <Text textStyle="h6" color="var(--grey-700)" maxW="39rem">
                When calls come in, it will run through the sequence of layers
                below. Each layer must have one or more agents. You can add and
                move layers as you like.
              </Text> */}
            </VStack>

            <VStack w="100%" alignItems="flex-start">
              <VStack alignItems="flex-start" width="100%" gap="0">
                {/* <HStack
                  justifyContent="flex-start"
                  w="100%"
                  alignItems="flex-start"
                >
                  <Text textStyle="h6" fontWeight="700" mb="0.5rem">
                    Select Line
                  </Text>
                </HStack> */}
                <VStack
                  alignItems="flex-start"
                  width="100%"
                  gap="1rem"
                  sx={{
                    ".async-select": {
                      width: "100%",
                    },
                  }}
                >
                  <AsyncSelect
                    loadOptions={(inputValue) => loadLines(inputValue)}
                    value={ringData?.line_ids}
                    className="async-select"
                    onChange={(value) => {
                      updateRingData("line_ids", value);
                    }}
                    placeholder="Select Line"
                    isMulti={true}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        minWidth: "100%",
                        borderRadius: "0.5rem",
                        borderColor: "var(--grey-300)",
                        padding: "0.25rem",
                      }),
                    }}
                  />
                </VStack>
              </VStack>
            </VStack>
          </VStack>
        </HStack>
      </VStack>
      <VStack
        gap="1.5rem"
        alignItems="flex-start"
        py="1.5rem"
        borderBottom="1px solid var(--grey-300)"
      >
        <HStack
          justifyContent="flex-start"
          w="100%"
          alignItems="flex-start"
          position="relative"
        >
          <Text textStyle="h5" fontWeight="700" w="25%">
            Ring Group Builder
          </Text>
          <VStack w="50%" maxW="30rem" alignItems="flex-start">
            <VStack alignItems="flex-start">
              <Text textStyle="h5" fontWeight="700">
              Add Users
              </Text>
              {/* <Text textStyle="h6" color="var(--grey-700)" maxW="39rem">
                When calls come in, it will run through the sequence of layers
                below. Each layer must have one or more agents. You can add and
                move layers as you like.
              </Text> */}
            </VStack>

            <VStack w="100%" alignItems="flex-start">
              <VStack alignItems="flex-start" width="100%" gap="0">
                {/* <HStack
                  justifyContent="flex-start"
                  w="100%"
                  alignItems="flex-start"
                >
                  <Text textStyle="h6" fontWeight="700" mb="0.5rem">
                    Select User
                  </Text>
                </HStack> */}
                <VStack
                  alignItems="flex-start"
                  width="100%"
                  gap="1rem"
                  sx={{
                    ".async-select": {
                      width: "100%",
                    },
                  }}
                >
                  <AsyncSelect
                    loadOptions={(inputValue) => loadEmails(inputValue)}
                    className="async-select"
                    value={ringData?.ring_group_users_attributes}
                    onChange={(value) => {
                      updateRingData("ring_group_users_attributes", value);
                    }}
                    placeholder="Select Users"
                    isMulti={true}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        minWidth: "100%",
                        borderRadius: "0.5rem",
                        borderColor: "var(--grey-300)",
                        padding: "0.25rem",
                      }),
                    }}
                  />
                </VStack>
              </VStack>
            </VStack>
          </VStack>
        </HStack>
      </VStack>
      <HStack
        bottom="0"
        w="100%"
        right="0"
        justifyContent="flex-end"
        padding="1rem"
        borderTop="1px solid var(--grey-300)"
      >
        <Button
          width="8rem"
          onClick={() => {
            ringId ? handleEdit() : handleSubmit();
          }}
        >
          Save
        </Button>{" "}
      </HStack>
      {(isLoading ||
        mutation.isLoading ||
        editMutation.isLoading ||
        editLineMutation.isLoading) && <Loader />}
    </Box>
  );
};

export default General;
