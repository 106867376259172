import { Box, HStack, Text, VStack } from "@chakra-ui/react";
import HandshakeIcon from "@mui/icons-material/Handshake";
import {
  AddUserIcon,
  BroadcastIcon,
  InboundEmailDashboardIcon,
  LeadUserIcon,
  OutBoundEmailDasboardIcon,
} from "../../icons/svg";
import CallLine from "./CallLine";

const AnalyticsBottomPane = ({ data }: any) => {
  const emailData = [
    {
      id: 1,
      title: "Outbound by User",
      icon: <InboundEmailDashboardIcon />,
      value: data?.emails?.total,
    },
    {
      id: 2,
      title: "Email Blast",
      icon: <OutBoundEmailDasboardIcon />,
      value: data?.emails?.outbound_by_users,
    },
    {
      id: 3,
      title: "Inbound ",
      icon: <InboundEmailDashboardIcon />,
      value: data?.emails?.inbound,
    },
  ];

  const recordsData = [
    {
      id: 1,
      title: "Appointments",
      setOnetitile: "Appointment Set",
      setTwotitile: "Appointment Shown",
      setOneData: data?.appointments?.set,
      setTwoData: data?.appointments?.shown,
      icon: <AddUserIcon />,
    },
    {
      id: 2,
      title: "Leads Engaged",
      setOnetitile: "Leads Engaged",
      setTwotitile: "Leads Engaged %",
      setOneData: data?.leads_engaged?.leads_engaged,
      setTwoData: data?.leads_engaged?.percentage,
      icon: <LeadUserIcon />,
    },
    {
      id: 3,
      title: "Broadcasts",
      setOnetitile: "Sent",
      setTwotitile: "Total Recipients",
      setOneData: data?.broadcasts?.sent,
      setTwoData: data?.broadcasts?.total_recipients,
      icon: <BroadcastIcon color="#1E88E5" />,
    },
    {
      id: 4,
      title: "Deals",
      // setOnetitile: "Sent",
      // setOneData: data?.deals?.sent,
      setOnetitile: "Total Deals",
      setTwoData: data?.deals?.total,
      icon: <HandshakeIcon sx={{ color: "#1E88E5" }} />,
    },
  ];

  return (
    <HStack w="100%" gap="1.5rem">
      <VStack
        w="calc(33% - 0.75rem)"
        p="1.5rem"
        borderRadius="1rem"
        border="1px solid var(--grey-300)"
        alignItems="flex-start"
      >
        <Text textStyle="h3" fontWeight="400" color="var(--grey-900)">
          Calls
        </Text>
        <Text textStyle="h2" fontWeight={700}>
          {data?.calls?.total_calls}
        </Text>
        <VStack gap="1.5rem" w="100%" mt="1rem">
          <CallLine
            answered={data?.calls?.inbound_calls?.answered}
            missed={data?.calls?.inbound_calls?.missed}
            answeredTitle="Answered"
            missedTitle="Missed"
            answredColor="var(--secondary-600)"
            missedColor="var(--yellow-800)"
            callTitle="Inbound Calls"
            totalCall={data?.calls?.inbound_calls?.total}
            totalCallText="Total inbound time"
            totalCallNumber={data?.calls?.inbound_calls?.total_inbound_minutes}
          />
          <CallLine
            answered={data?.calls?.outbound_calls?.connected}
            missed={data?.calls?.outbound_calls?.not_connected}
            answeredTitle="Connected"
            missedTitle="Not connected"
            answredColor="var(--primary-600)"
            missedColor="var(--red-600)"
            callTitle="Outbound Calls"
            totalCall={data?.calls?.outbound_calls?.total}
            totalCallText="Total outbound time"
            totalCallNumber={
              data?.calls?.outbound_calls?.total_outbound_minutes
            }
          />
        </VStack>

        <HStack w="100%" justifyContent="space-between" mt="2.5rem">
          <VStack alignItems="flex-start">
            <Text textStyle="h6" fontWeight={600}>
              Avg. Outbound Call Talk Time
            </Text>
            <Text textStyle="h4" fontWeight={700} color="var(--grey-900)">
              {data?.calls?.avg_outbound_calls_talk_time}
            </Text>
          </VStack>
          <VStack alignItems="flex-start">
            <Text textStyle="h6" fontWeight={600}>
              Avg. Inbound Call Talk Time
            </Text>
            <Text textStyle="h4" fontWeight={700} color="var(--grey-900)">
              {data?.calls?.avg_inbound_calls_talk_time}
            </Text>
          </VStack>
        </HStack>
      </VStack>

      <VStack
        w="calc(33% - 0.75rem)"
        p="2rem 1.5rem"
        borderRadius="1rem"
        border="1px solid var(--grey-300)"
        alignItems="flex-start"
      >
        <Text textStyle="h3" fontWeight="400" color="var(--grey-900)">
          Emails
        </Text>
        <Text textStyle="h2" fontWeight={700}>
          {data?.emails?.total}
        </Text>

        <VStack
          borderRadius="1rem"
          border="1px solid var(--grey-300)"
          background="var(--grey-50)"
          w="100%"
          mt="1.35rem"
        >
          {emailData.map((item) => (
            <VStack
              w="100%"
              borderBottom="1px solid var(--grey-300)"
              key={item.id}
              sx={{
                "&:last-child": {
                  borderBottom: "none",
                },
              }}
            >
              <HStack
                w="100%"
                gap="1rem"
                p="1.3125rem 1.25rem"
                justifyContent="space-between"
              >
                <HStack>
                  <Box
                    p="0.6rem 0.5rem"
                    background="var(--secondary-50)"
                    borderRadius="0.375rem"
                  >
                    {item.icon}
                  </Box>
                  <Text textStyle="h3" fontWeight="600" color="var(--grey-900)">
                    {item.title}
                  </Text>
                </HStack>
                <HStack>
                  <Text textStyle="h3" fontWeight="600" color="var(--grey-900)">
                    {item.value}
                  </Text>
                </HStack>
              </HStack>
            </VStack>
          ))}
        </VStack>
      </VStack>

      <VStack w="calc(33% - 0.75rem)" alignItems="flex-start" gap="0.5rem">
        {recordsData.map((data) => (
          <VStack
            p="0.75rem 1.5rem"
            borderRadius="1rem"
            border="1px solid var(--grey-300)"
            alignItems="flex-start"
            key={data?.id}
            w="100%"
            gap="0px"
          >
            <HStack>
              {data?.icon}
              <Text textStyle="h4" fontWeight="700" color="var(--grey-900)">
                {data?.title}
              </Text>
            </HStack>

            <HStack
              mt="0.75rem"
              w="100%"
              justifyContent="space-between"
              gap="2.25rem"
            >
              <VStack alignItems="flex-start" gap="0rem" w="8.75rem">
                <Text textStyle="h5" fontWeight="500" whiteSpace="nowrap">
                  {data?.setOnetitile}
                </Text>
                <Text textStyle="h3" fontWeight="700" color="var(grey-900)">
                  {data?.setOneData}
                </Text>
              </VStack>

              <VStack alignItems="flex-start" gap="0rem" w="8.75rem">
                <Text textStyle="h5" fontWeight="500" whiteSpace="nowrap">
                  {data?.setTwotitile}
                </Text>
                <Text textStyle="h3" fontWeight="700" color="var(grey-900)">
                  {data?.setTwoData}
                </Text>
              </VStack>
            </HStack>
          </VStack>
        ))}
      </VStack>
    </HStack>
  );
};

export default AnalyticsBottomPane;
