import {
  Box,
  Button,
  Checkbox,
  DrawerFooter,
  HStack,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import CancelIcon from "@mui/icons-material/Cancel";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import AsyncSelect from "react-select/async";
import {
  CustomRadio,
  Drawer,
  Input,
  PaginatedTable,
  Select,
  Spinner,
} from "../../../components/common";
import {
  BigCheckIcon,
  CloseIcon,
  DarkWarningIcon,
  GreenDownloadIcon,
  SimpleDownloadIcon,
  UploadFillIcon,
} from "../../../components/icons/svg";
import { CustomSelect } from "../../../theme/components";
import { fetchTags } from "../../../utils/api/global-config.api";
import {
  downloadAttachment,
  downloadSample,
  importContacts,
  validateFile,
} from "../../../utils/api/import-contacts.api";
import { loadDPUsersApi } from "../../../utils/api/users.api";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";

export default function ImportContacts(props: any) {
  const { onClose } = props;
  const dealerId = useUserInfo("dealership");
  const { id: dId } = useParams();
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [nextEnabled, setNextEnabled] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(true);
  const [isFileDownloading, setIsFileDownloading] = useState<boolean>(false);
  const [fileData, setFileData] = useState<any>();

  //.......................................................
  // step 1
  const [dnc, setDnc] = useState<boolean>(false);

  // step 2
  const [fileName, setFileName] = useState<string>("");

  // step 3
  const [validCount, setValidCount] = useState<number>(0);
  const [invalidCount, setInvalidCount] = useState<number>(0);
  const [fileId, setFileId] = useState<any>();

  // step 4
  const [contactType, setContactType] = useState("default");
  const [salesPerson, setSalesPerson] = useState<SelectOptionProps | null>(
    null
  );
  const [bdcAgent, setBDCAgent] = useState<SelectOptionProps | null>(null);
  const [manager, setManager] = useState<SelectOptionProps | null>(null);
  const [bdcCampaign, setBDCCampaign] = useState<SelectOptionProps | null>(
    null
  );
  const [tags, setTags] = useState<SelectOptionProps[] | null>(null);
  //.......................................................

  const validFileTypes = [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "text/csv",
  ];
  const maxFileSize = 50 * 1024 * 1024;
  const [file, setFile] = useState(null);
  const toast = useToast();

  const mutation = useMutation(validateFile);
  const mutationImportContacts = useMutation(importContacts);

  const uploadFileValidation = (file: any) => {
    if (file) {
      if (validFileTypes.includes(file.type) && file.size < maxFileSize) {
        setFile(file);
      } else {
        toast({
          title: "Invalid File",
          description: "Please upload a valid XLSX or CSV file less than 50MB.",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
      }
    }
  };
  const { data, isLoading } = useQuery({
    queryKey: ["fetchTagsForImportContacts", dId, dealerId],
    queryFn: () => fetchTags({}, dId ?? dealerId?.id),
    refetchOnWindowFocus: false,
    retry: false,
  });

  useEffect(() => {
    if (currentStep === 0) {
      setNextEnabled(dnc);
    }
  }, [currentStep, dnc]);

  useEffect(() => {
    if (currentStep === 1) {
      if (file && fileName) {
        setNextEnabled(true);
      } else {
        setNextEnabled(false);
      }
    }
  }, [currentStep, file, fileName]);

  useEffect(() => {
    if (currentStep === 2) {
      mutation.mutate(
        { dId: dId ?? dealerId?.id, payload: { file: file, name: fileName } },
        {
          onSuccess: (data: any) => {
            console.log("success");
            setFileData(data?.data?.contacts);
            setValidCount(data?.data?.valid_contacts);
            setInvalidCount(data?.data?.invalid_contacts);
            setFileId(data?.data?.contact_import_id);
            toast({
              description: "File processed successfully.",
              status: "success",
              duration: 5000,
              isClosable: true,
              position: "top",
            });
          },
          onError: (error: any) => {
            console.log("error");
            setFileData(data?.data?.contacts);
            setNextEnabled(false);
            setFileData(error?.response?.data?.contacts);
            setValidCount(error?.response?.data?.valid_contacts);
            setInvalidCount(error?.response?.data?.invalid_contacts);
            toast({
              description: `Error processing file: ${
                error?.response?.data?.message?.toString() ??
                "Something went wrong"
              }`,
              status: "error",
              duration: 5000,
              isClosable: true,
              position: "top",
            });
          },
        }
      );
    }
  }, [currentStep]);

  useEffect(() => {
    if (currentStep === 4) {
      mutationImportContacts.mutate(
        {
          dId: dId ?? dealerId?.id,
          fileId,
          payload: {
            contact: {
              contact_type: contactType,
              sales_person_id: salesPerson?.value || "",
              bdc_agent_id: bdcAgent?.value || "",
              manager_id: manager?.value || "",
              tags: tags?.map((item: any) => item.value) || [],
            },
          },
        },
        {
          onSuccess: (data) => {
            setIsSuccess(true);
            toast({
              description: "File processed successfully.",
              status: "success",
              duration: 5000,
              isClosable: true,
              position: "top",
            });
          },
          onError: (error: any) => {
            setIsSuccess(false);
            toast({
              description: `Error processing file: ${
                error?.response?.data?.errors ?? "Something went wrong"
              }`,
              status: "error",
              duration: 5000,
              isClosable: true,
              position: "top",
            });
          },
        }
      );
    }
  }, [currentStep]);

  const tableHeader = [
    { value: "First Name", label: "first_name" },
    { value: "Last Name", label: "last_name" },
    { value: "Email", label: "email" },
    { value: "Phone Number", label: "phone_number" },
    { value: "Work Number", label: "work_number" },
    { value: "Home Number", label: "home_number" },
    { value: "Address", label: "address" },
    { value: "Year", label: "year" },
    { value: "Make", label: "make" },
    { value: "Model", label: "model" },
    { value: "Notes", label: "notes" },
    { value: "Contact Created Date", label: "contact_created_date" },
    { value: "Lead Source", label: "lead_source" },
    { value: "Tags", label: "tags" },
    { value: "Sales Person Email", label: "sales_person_email" },
    { value: "Manager Email", label: "manager_email" },
    { value: "BDC Agent Email", label: "bdc_agent_email" },
  ];

  const tableData = fileData?.map((item: any) => ({
    first_name: item["first_name"],
    last_name: item["last_name"],
    email: item["email"],
    phone_number: item["phone_number"],
    work_number: item["work_number"],
    home_number: item["home_number"],
    address: item["address"],
    year: item["year"],
    make: item["make"],
    model: item["model"],
    notes: item["notes"],
    contact_created_date: item["contact_created_date"],
    lead_source: item["lead_source"],
    tags: item["tags"],
    sales_person_email: item["sales_person_email"],
    manager_email: item["manager_email"],
    bdc_agent_email: item["bdc_agent_email"],
  }));

  const loadActiveDPUsers = async (inputValue: string, role: string) => {
    try {
      const response = await loadDPUsersApi({
        dealership_id: dId ?? dealerId?.id,
        role_titles: role,
        status: "active",
        q: inputValue,
      });
      const options = response?.users?.map((user: any) => {
        return {
          value: user.id,
          label: user.full_name,
        };
      });
      return options;
    } catch (error) {
      console.error("Error fetching user data:", error);
      return [];
    }
  };
  const downloadSampleFile = async () => {
    try {
      setIsFileDownloading(true);
      const response = await downloadSample(dId ?? dealerId?.id);
      setIsFileDownloading(false);
      const blob = new Blob([response?.data]);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "Sample File.csv";
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading", error);
    }
  };
  const downloadAttachmentFile = async () => {
    try {
      setIsFileDownloading(true);
      const response = await downloadAttachment(dId ?? dealerId?.id);
      setIsFileDownloading(false);
      const blob = new Blob([response?.data]);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "Error File.csv";
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading", error);
    }
  };

  const importSteps = [
    {
      step: 0,
      content: (
        <VStack w="100%" alignItems="flex-start">
          <VStack
            padding="1rem"
            w="100%"
            alignItems="flex-start"
            background="var(--secondary-50)"
          >
            <Checkbox
              size="lg"
              variant="whiteCheck"
              display="flex"
              alignItems="flex-start !important"
              onChange={(e) => setDnc(e.target.checked)}
              fontSize="0.875rem"
              fontWeight="500"
              color="var(--grey-800)"
              isChecked={dnc}
            >
              <Box
                as="span"
                fontSize="1rem"
                fontWeight="700"
                color="var(--grey-800)"
              >
                DNC Agreement -
              </Box>{" "}
              You verify this list does not contain any phone numbers found in
              the United States DNC (Do not Call) List or your own DNC records
            </Checkbox>
            <Text textStyle="h5" fontWeight="500">
              Organizations that lead phone numbers owners have requested DNC
              status may run the risk of legal action
            </Text>
            <Text textStyle="h6" fontWeight="500">
              To help avoid legal action, scrub all lead s lists before you
              import them using a DNC Checking Service. SimpSocial can assist
              you with this.
            </Text>
          </VStack>
          <VStack padding="1rem" w="100%" alignItems="flex-start">
            <Text textStyle="h4" fontWeight="500">
              You must agree to the DNC Agreement above the Importing
            </Text>
            <Text textStyle="h5" fontWeight="400">
              Want to test, but don’t have a clean list?
            </Text>
            <Text textStyle="h5" fontWeight="400">
              Agree to the terms above, download and then upload this example
              list to test import process
            </Text>
            <Button
              variant="outline"
              gap="0.25rem"
              color="var(--secondary-600)"
              onClick={() => downloadSampleFile()}
            >
              <GreenDownloadIcon />
              <Text>Download Contacts Template</Text>
            </Button>
          </VStack>
        </VStack>
      ),
    },
    {
      step: 1,
      content: (
        <VStack w="100%" alignItems="center" gap="4.5rem">
          <VStack w="60%">
            <Input
              color="var(--grey-900)"
              isRequired={true}
              label="Batch File Name"
              placeholder="Enter Batch File Name"
              value={fileName}
              onChange={(e) => {
                setFileName(e.target.value);
              }}
            ></Input>
          </VStack>
          <VStack
            position="relative"
            width="100%"
            gap="2rem"
            textAlign="center"
            w="22rem"
            h="18rem"
            justifyContent="center"
            alignItems="center"
            border="1px dashed var(--secondary-600)"
            borderRadius="0.5rem"
            background="var(--grey-50)"
            mx="auto"
            onDrop={(event: any) => {
              event.preventDefault();
              if (!file) {
                const droppedFile = event.dataTransfer.files[0];
                uploadFileValidation(droppedFile);
              }
              // clear the file so if we upload the same file it appears instead of going blank
              event.target.value = "";
            }}
            onDragOver={(event) => event.preventDefault()}
          >
            <UploadFillIcon />

            <Text textStyle="h5" color="var(--grey-900)" fontWeight="500">
              Drop your Excel or CSV file here or
            </Text>
            <Input
              id="fileInput"
              type="file"
              onChange={(event: ChangeEvent) => {
                const file = event.target.files[0];
                uploadFileValidation(file);
                event.target.value = "";
              }}
              style={{ display: "none" }}
            />
            <Button
              variant="outline"
              color="var(--secondary-600)"
              borderColor="var(--secondary-600)"
              isDisabled={file ? true : false}
              onClick={() => {
                if (document?.getElementById)
                  document?.getElementById("fileInput")?.click();
              }}
            >
              Upload From Computer
            </Button>
            <HStack>
              {file && (
                <>
                  <Text textStyle="h5">{file["name"]}</Text>
                  <Button variant="none" onClick={() => setFile(null)}>
                    <CloseIcon />
                  </Button>
                </>
              )}
            </HStack>
          </VStack>
          <VStack gap="1rem">
            <Text textStyle="h5">
              To get started with a file template, click link below
            </Text>
            <Button
              variant="outline"
              gap="0.25rem"
              color="var(--secondary-600)"
              onClick={() => downloadSampleFile()}
              mb="1rem"
            >
              <GreenDownloadIcon />
              <Text>Download Contacts Template</Text>
            </Button>
          </VStack>
        </VStack>
      ),
    },
    {
      step: 2,
      content: (
        <VStack w="100%" alignItems="flex-start">
          <VStack w="100%" background="var(--secondary-50)" padding="1rem">
            <HStack alignItems="flex-start" w="100%">
              <Box w="10%" mt="0.2rem">
                <DarkWarningIcon />
              </Box>
              <Text textStyle="h6" color="var(--grey-800)">
                <Box
                  as="span"
                  fontSize="1rem"
                  fontWeight="700"
                  color="var(--grey-800)"
                >
                  Instructions -
                </Box>{" "}
                We have fetched the first 10 rows in your file so you can assign
                each columns to its appropriate property. Take note that at
                least one phone number (Separated by comma if multiply) is
                required to move on to the next step.
              </Text>
            </HStack>
          </VStack>
          <Box w="100%" alignItems="flex-start" background="white">
            <Text
              textStyle="h4"
              padding="0.5rem 1rem 1rem 1rem"
              w="100%"
              fontWeight="500"
            >
              Preview Mapping
            </Text>
            <PaginatedTable
              header={tableHeader}
              data={tableData}
              itemsPerPage={10}
              maxPageNumbers={3}
              tableHeight={560}
            />
          </Box>
          <VStack gap="0.5rem" alignItems="center" w="100%">
            <Text textStyle="h5" fontWeight="600">
              Successful Contact Imports : {validCount}
            </Text>
            <HStack
              padding="0.75rem"
              background="var(--grey-50)"
              borderRadius="0.5rem"
            >
              <Text color="#C13A3A" textStyle="h5" fontWeight="600">
                Unsuccessful Contact Imports : {invalidCount}
              </Text>
              {invalidCount > 0 && (
                <Box
                  padding="0.38rem"
                  borderRadius="0.5rem"
                  border="1px solid var(--grey-300)"
                  cursor="pointer"
                  onClick={() => downloadAttachmentFile()}
                >
                  <SimpleDownloadIcon />
                </Box>
              )}
            </HStack>
          </VStack>
          <VStack w="100%" padding="1rem">
            {invalidCount > 0 && (
              <HStack alignItems="flex-start" w="100%">
                <DarkWarningIcon />
                <Text textStyle="h6" color="var(--red-800)">
                  Kindly, go back to the previous step and re-upload the file
                  after fixing these errors.
                </Text>
              </HStack>
            )}
          </VStack>
        </VStack>
      ),
    },
    {
      step: 3,
      content: (
        <VStack w="100%" alignItems="flex-start" padding="0 1.5rem" gap="1rem">
          <VStack
            padding="1rem"
            border="1px solid var(--grey-300)"
            background="var(--grey-50)"
            borderRadius="0.5rem"
            alignItems="flex-start"
            w="100%"
          >
            <CustomRadio
              options={[
                { label: "Enter into New Lead Pool", value: "default" },
                { label: "Priority#1", value: "priority_1" },
                { label: "Priority#2", value: "priority_2" },
                { label: "Priority#3", value: "priority_3" },
                { label: "Leave Out of Priority", value: "no_priority" },
              ]}
              direction="column"
              label={
                <Text textStyle="h4" fontWeight="600">
                  Contact Type
                  <Box as="span" color="var(--red-600)">
                    *
                  </Box>
                </Text>
              }
              value={contactType}
              setValue={(value: string) => setContactType(value)}
            />
          </VStack>
          <VStack w="100%" gap="1rem" alignItems="flex-start" mt="1rem">
            <VStack
              padding="1rem"
              border="1px solid var(--grey-300)"
              background="var(--grey-50)"
              borderRadius="0.5rem"
              alignItems="flex-start"
              w="100%"
            >
              <Text textStyle="h4" fontWeight="600">
                Assign Contacts to
              </Text>
              <Box maxW="100%" minW="auto" w="100%" height="auto">
                <Text
                  textStyle="h4"
                  fontWeight="600"
                  fontSize="0.875rem"
                  color="var(--grey-900)"
                >
                  Sales Person
                </Text>
                <AsyncSelect
                  styles={{ ...CustomSelect?.styles?.customSelect?.default }}
                  loadOptions={(inputValue) =>
                    loadActiveDPUsers(inputValue, "Sales Person")
                  }
                  defaultOptions
                  onChange={(value: any) => setSalesPerson(value)}
                  placeholder="Type to search..."
                  value={salesPerson}
                />
              </Box>
              <Box maxW="100%" minW="auto" w="100%" height="auto">
                <Text
                  textStyle="h4"
                  fontWeight="600"
                  fontSize="0.875rem"
                  color="var(--grey-900)"
                >
                  BDC Agent
                </Text>
                <AsyncSelect
                  styles={{ ...CustomSelect?.styles?.customSelect?.default }}
                  loadOptions={(inputValue) =>
                    loadActiveDPUsers(inputValue, "BDC Agent")
                  }
                  defaultOptions
                  onChange={(value: any) => setBDCAgent(value)}
                  placeholder="Type to search..."
                  value={bdcAgent}
                />
              </Box>
              <Box maxW="100%" minW="auto" w="100%" height="auto">
                <Text
                  textStyle="h4"
                  fontWeight="600"
                  fontSize="0.875rem"
                  color="var(--grey-900)"
                >
                  Manager
                </Text>
                <AsyncSelect
                  styles={{ ...CustomSelect?.styles?.customSelect?.default }}
                  loadOptions={(inputValue) =>
                    loadActiveDPUsers(inputValue, "Manager/Admin")
                  }
                  defaultOptions
                  onChange={(value: any) => setManager(value)}
                  placeholder="Type to search..."
                  value={manager}
                />
              </Box>
            </VStack>
            {/* <VStack
              padding="1rem"
              border="1px solid var(--grey-300)"
              background="var(--grey-50)"
              borderRadius="0.5rem"
              alignItems="flex-start"
              w="100%"
            >
              <Text textStyle="h4" fontWeight="600">
                Enroll into BDC Campaign
              </Text>

              <Select
                label="Select campaign that you want to enrol these contacts to"
                onChange={(value: any) => setBDCCampaign(value)}
                value={bdcCampaign}
                placeholder="Select Campaign"
                options={[]}
                w="100%"
                variant="default"
              />
            </VStack> */}
            <VStack
              padding="1rem"
              border="1px solid var(--grey-300)"
              background="var(--grey-50)"
              borderRadius="0.5rem"
              alignItems="flex-start"
              w="100%"
            >
              <Text textStyle="h4" fontWeight="600">
                Tags
              </Text>
              <Select
                label="Select tags that you want to assign to these contacts"
                onChange={(value: any) => setTags(value)}
                value={tags}
                placeholder="Select Tags"
                options={
                  data?.tags?.map((item: any) => ({
                    label: item?.name,
                    value: item?.id,
                  })) || []
                }
                w="100%"
                variant="default"
                isMulti={true}
              />
            </VStack>
          </VStack>
        </VStack>
      ),
    },
    {
      step: 4,
      content: (
        <VStack w="100%" alignItems="center" gap="1rem" h="100%" mt="7rem">
          {isSuccess && (
            <>
              <Text textStyle="h3" fontWeight="600">
                Contacts Imported Successfully!
              </Text>
              <VStack gap="0.5rem">
                <BigCheckIcon />
              </VStack>
            </>
          )}
          {!isSuccess && (
            <>
              <Text textStyle="h3" fontWeight="600">
                Error while Importing Contacts!
              </Text>
              <VStack gap="0.5rem">
                <CancelIcon
                  style={{
                    width: "8rem",
                    height: "8rem",
                    color: "var(--red-600)",
                  }}
                />
              </VStack>
            </>
          )}
        </VStack>
      ),
    },
  ];

  return (
    <Drawer isOpen={true} onClose={onClose} title="Import Wizard">
      <HStack w="100%" gap="0" padding="1.5rem 4rem">
        {importSteps.map((item) => (
          <HStack
            key={item.step}
            gap="0"
            flex={importSteps.length - 1 !== item.step ? "1" : "0"}
          >
            <Text
              borderRadius="50%"
              minW="2.375rem"
              h="2.375rem"
              display="flex"
              alignItems="center"
              justifyContent="center"
              color={currentStep >= item.step ? "white" : "var(--grey-500)"}
              textAlign="center"
              border="1px solid var(--grey-500)"
              background={
                currentStep >= item.step
                  ? "var(--secondary-600)"
                  : "transparent"
              }
            >
              {item.step + 1}
            </Text>
            {importSteps.length - 1 !== item.step && (
              <Box
                h="1px"
                w="100%"
                background={
                  currentStep > item.step
                    ? "var(--secondary-600)"
                    : "var(--grey-300)"
                }
              ></Box>
            )}
          </HStack>
        ))}
      </HStack>
      {importSteps[currentStep].content}
      <DrawerFooter
        position="absolute"
        bottom="0"
        left="0"
        width="100%"
        borderTop="1px solid var(--grey-300)"
        display="flex"
        alignItems="center"
        gap="0.81rem"
      >
        {currentStep > 0 && currentStep < importSteps.length - 1 && (
          <Button
            variant="outline"
            w="100%"
            size="xl"
            onClick={() => setCurrentStep(currentStep - 1)}
          >
            <ChevronLeftIcon />
            Back
          </Button>
        )}
        {currentStep < importSteps.length - 1 ? (
          <Button
            w="100%"
            size="xl"
            isDisabled={!nextEnabled}
            onClick={() => setCurrentStep(currentStep + 1)}
          >
            {currentStep < importSteps.length - 2 ? "Next" : "Done"}
            <ChevronRightIcon />
          </Button>
        ) : (
          <Button w="100%" size="xl" onClick={onClose}>
            Done
          </Button>
        )}
      </DrawerFooter>
      {(isFileDownloading ||
        isLoading ||
        mutationImportContacts?.isLoading ||
        mutation?.isLoading) && <Spinner></Spinner>}
    </Drawer>
  );
}
