import {
  Button,
  DrawerFooter,
  HStack,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import DeleteIcon from "@mui/icons-material/Delete";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { useParams } from "react-router-dom";
import {
  createTemplate,
  createTemplateWithDealershipId,
} from "../../../../utils/api/scorecard-settings.api";
import { Drawer, Input } from "../../../common";
import { CircleGreenAddIcon } from "../../../icons/svg";

type data = {
  isOpen: boolean;
  onClose: any;
  refetchParent: any;
};
interface ApiResult {
  data: any;
}
type Condition = {
  keyword?: string;
  times?: string;
  score?: string;
};

type ApiRequest = {
  scorecard: {
    name: string;
    category: string;
    conditions_attributes: Condition[];
  };
};

const radioOptions = [
  { label: "SMS", value: "sms" },
  { label: "Calls", value: "calls" },
];

const CreateTemplate = (props: data) => {
  const { id } = useParams();
  const { isOpen, onClose, refetchParent } = props;
  const toast = useToast();
  const [name, setName] = useState("");
  // const [selectedCategory, setSelectedCategory] = useState<string>("sms");
  const [conditions, setConditions] = useState<Condition[]>([
    {
      keyword: "",
      times: "0",
      score: "0",
    },
  ]);

  const [nameError, setNameError] = useState("");
  const [error, setError] = useState("");

  const mutation = useMutation<ApiResult, Error, ApiRequest, unknown>({
    mutationFn: async (payload) => {
      if (id) {
        try {
          const response = await createTemplateWithDealershipId(id, payload);
          return { data: response.data };
        } catch (error) {
          throw error;
        }
      } else {
        try {
          const response = await createTemplate(payload);
          return { data: response.data };
        } catch (error) {
          throw error;
        }
      }
    },
    onSuccess: () => {
      refetchParent();
      onClose();
      toast({
        description: "Scorecard created successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
    onError: (error: any) => {
      toast({
        description: `Error creating scorecard: ${
          error?.response?.data?.errors?.toString() ?? "Something went wrong"
        }`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
  });

  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    let hasErrors = false;

    if (!name?.trim()) {
      setNameError("Name cannot be empty");
      hasErrors = true;
    } else {
      setNameError("");
    }

    if (conditions.length === 0) {
      setError("Define at least one condition");
      hasErrors = true;
    } else {
      const isAnyKeywordEmpty = conditions.some(
        (condition) => !condition?.keyword?.trim()
      );
      if (isAnyKeywordEmpty) {
        setError("Keywords for conditions cannot be empty.");
        hasErrors = true;
      } else {
        setError("");
      }
    }

    if (hasErrors) return;
    setNameError("");

    const requestData: ApiRequest = {
      scorecard: {
        name: name,
        category: "calls",
        conditions_attributes: conditions,
      },
    };
    mutation.mutate(requestData);
  };
  const handleAddCondition = () => {
    const newCondition: Condition = {
      keyword: "",
      times: "0",
      score: "0",
    };

    setConditions((prevConditions) => [...prevConditions, newCondition]);
  };

  const handleDeleteCondition = (index: number) => {
    setConditions((prevConditions) =>
      prevConditions.filter((_, i) => i !== index)
    );
  };

  const handleKeywordChange = (index: number, value: string) => {
    setError("");
    setConditions((prevConditions) => {
      const updatedConditions = [...prevConditions];
      updatedConditions[index].keyword = value;
      return updatedConditions;
    });
  };

  const handleTimesChange = (index: number, value: number) => {
    if (Number.isInteger(value) && value >= 0 && value <= 100) {
      setError("");
      setConditions((prevConditions) => {
        const updatedConditions = [...prevConditions];
        updatedConditions[index].times = value.toString();
        return updatedConditions;
      });
    } else {
      setError("Times should be a whole number between 0 and 100.");
    }
  };

  const handleScoreChange = (index: number, value: number) => {
    if (value >= 0 && value <= 100) {
      setError("");
      setConditions((prevConditions) => {
        const updatedConditions = [...prevConditions];
        updatedConditions[index].score = value.toString();
        return updatedConditions;
      });
    } else {
      setError("Score should be a number between 0 and 100.");
    }
  };

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      title="Create Scorecard"
      isLoading={mutation?.isLoading}
    >
      <VStack padding="1.5rem" w="100%">
        <VStack
          alignItems="flex-start"
          padding="1rem"
          w="100%"
          gap="1rem"
          background="var(--grey-100)"
          borderRadius="0.5rem"
          border="1px solid var(--grey-300)"
        >
          <Input
            placeholder="Enter Scorecard Name"
            label="Name of Scorecard"
            onChange={(e: any) => {
              setNameError("");
              setName(e.target.value);
            }}
            value={name}
            isRequired={true}
            error={nameError}
          />
          {/* <VStack alignItems="flex-start" w="100%">
            <Text textStyle="h6" fontWeight="600" pb="10px">
              Create for Calls
            </Text>
            {radioOptions.map((option) => (
              <Radio
                key={option.value}
                value={option.value}
                isChecked={selectedCategory === option.value}
                onChange={() => {
                  setSelectedCategory(option.value);
                }}
              >
                {option.label}
              </Radio>
            ))}
          </VStack> */}

          {conditions.map((item, index) => (
            <VStack
              alignItems="flex-start"
              w="100%"
              borderBottom="1px solid var(--grey-300)"
              pb="1rem"
            >
              <HStack w="100%" alignItems="center">
                <Text textStyle="h6" mt="1rem">
                  If
                </Text>
                <Text textStyle="h6" mt="1rem" fontWeight="700">
                  {"Calls"}
                </Text>
                <Text textStyle="h6" mt="1rem">
                  Contains
                </Text>
                <Input
                  label="Keyword"
                  placeholder="Keyword"
                  variant="default"
                  w="200px"
                  value={item.keyword}
                  onChange={(e: any) =>
                    handleKeywordChange(index, e.target.value)
                  }
                />
                <Input
                  label="Times"
                  type="number"
                  value={item.times}
                  placeholder="0"
                  onChange={(e: any) =>
                    handleTimesChange(index, Number(e.target.value))
                  }
                  w="60px"
                />
                <Text textStyle="h6" mt="1rem">
                  x
                </Text>
                <Button
                  variant="none"
                  mt="1.5rem"
                  onClick={() => handleDeleteCondition(index)}
                >
                  <DeleteIcon
                    style={{ color: "var(--grey-800)", width: "1.2rem" }}
                  />
                </Button>
              </HStack>
              <HStack>
                <Text textStyle="h6" w="max-content" whiteSpace="nowrap">
                  Then, Set Score
                </Text>
                <Input
                  ml="10px"
                  w="60px"
                  type="number"
                  value={item.score}
                  placeholder="0"
                  onChange={(e: any) => {
                    handleScoreChange(index, Number(e.target.value));
                  }}
                />
                <Text textStyle="h6" w="max-content" whiteSpace="nowrap">
                  px
                </Text>
              </HStack>
            </VStack>
          ))}
          <VStack alignItems="flex-start" w="100%">
            <Button
              variant="none"
              display="flex"
              alignItems="center"
              gap="0.69rem"
              onClick={handleAddCondition}
            >
              <CircleGreenAddIcon />
              <Text color="var(--secondary-600)" textStyle="h5">
                Create Condition
              </Text>
            </Button>
            {error && (
              <Text color="red" textStyle="h5">
                {error}
              </Text>
            )}
          </VStack>
        </VStack>
      </VStack>
      <DrawerFooter
        position="absolute"
        bottom="0"
        left="0"
        width="100%"
        borderTop="1px solid var(--grey-300)"
        display="flex"
        alignItems="center"
        gap="0.81rem"
      >
        <Button variant="outline" w="100%" size="xl" onClick={onClose}>
          Cancel
        </Button>
        <Button w="100%" size="xl" onClick={handleSubmit}>
          Confirm
        </Button>
      </DrawerFooter>
    </Drawer>
  );
};

export default CreateTemplate;
