import axios, { InternalAxiosRequestConfig } from "axios";
import { Fragment, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import CreateTemplate from "../components/EmailBlast/Template/CreateTemplate";
import NotFound from "../components/NotFound/NotFound";
import ForbiddenPage from "../components/NotFound/NotPermitted";
import GuestModeLayoutClient from "../pages/(dashboard-routes)/GuestModeClient";
import ApplyForCredit from "../pages/(dashboard-routes)/apply-for-credit/page";
import AutoLoanCalculator from "../pages/(dashboard-routes)/auto-loan-calculator/page";
import BDCCampaignPage from "../pages/(dashboard-routes)/bdc-campaign/page";
import BDCCampaignSettingsPage from "../pages/(dashboard-routes)/bdc-campaign/settings/page";
import BroadcastMessagePage from "../pages/(dashboard-routes)/broadcast/page";
import BrowseInventory from "../pages/(dashboard-routes)/browser-inventory/page";
import CommunicationLogPage from "../pages/(dashboard-routes)/communication-log/page";
import RVMCommunicationLogPage from "../pages/(dashboard-routes)/communication-log/RVMCommunicationLogs";
import DeskLog from "../pages/(dashboard-routes)/desk-log/page";
import DigitalLeadPage from "../pages/(dashboard-routes)/digital-lead/page";
import DMSEquityPage from "../pages/(dashboard-routes)/dms-equity/page";
import ComposeBlastPage from "../pages/(dashboard-routes)/email-blast/compose-blast/page";
import EmailBlastPage from "../pages/(dashboard-routes)/email-blast/page";
import TemplatePage from "../pages/(dashboard-routes)/email-blast/template/page";
import EmailCampaignPage from "../pages/(dashboard-routes)/email-campaign/page";
import EmailsCommunicationLogPage from "../pages/(dashboard-routes)/emails-communication-log/page";
import GuestMode from "../pages/(dashboard-routes)/guest-mode/page";
import GuestModeDocuments from "../pages/(dashboard-routes)/guest_mode-documents/Page";
import LayoutClient from "../pages/(dashboard-routes)/layoutClient";
import LeadDetailsPage from "../pages/(dashboard-routes)/leads/lead-details/LeadDetailsPage";
import LeadsPage from "../pages/(dashboard-routes)/leads/page";
import CreateRingGroupPage from "../pages/(dashboard-routes)/lines-ring-groups/create-ring-group/page";
import LineSettingsGroup from "../pages/(dashboard-routes)/lines-ring-groups/line-settings/page";
import LiveTracking from "../pages/(dashboard-routes)/live-tracking/page";
import MeetingCalendarPage from "../pages/(dashboard-routes)/meeting-calendar/page";
import MyGarage from "../pages/(dashboard-routes)/my-garage/Page";
import MyProfile from "../pages/(dashboard-routes)/my-profile/page";
import PowerDialerDetailPage from "../pages/(dashboard-routes)/power-dialer/leads/page";
import PowerDialerPage from "../pages/(dashboard-routes)/power-dialer/page";
import PwCommunicationLogPage from "../pages/(dashboard-routes)/pw-communication-log/page";
import ReportsPage from "../pages/(dashboard-routes)/reports/page";
import SarahAIPage from "../pages/(dashboard-routes)/sarah/page";
import ScheduleVisit from "../pages/(dashboard-routes)/schedule-visit/page";
import SocialTalk from "../pages/(dashboard-routes)/social-talk/page";
import TagPage from "../pages/(dashboard-routes)/tags/page";
import TraniningPage from "../pages/(dashboard-routes)/training/TrainingPage";
import DPUsers from "../pages/(dashboard-routes)/users/DPUsers";
import UsersSettingPage from "../pages/(dashboard-routes)/users/settings/page";
import VehicleDetail from "../pages/(dashboard-routes)/vehicle/VehicleDetail";
import VideoChatPage from "../pages/(dashboard-routes)/video-chat/page";
import ActivityLogPage from "../pages/(global-admin-routes)/activity-log/ActivityLogPage";
import CommunicationLog from "../pages/(global-admin-routes)/announcements/CommunicationLog";
import ComposeBlast from "../pages/(global-admin-routes)/announcements/ComposeBlast";
import Announcements from "../pages/(global-admin-routes)/announcements/page";
import BillingPage from "../pages/(global-admin-routes)/billing/page";
import CampaignRegisterationPage from "../pages/(global-admin-routes)/campaign-registeration/page";
import Dashboard from "../pages/(global-admin-routes)/dashboard/page";
import DealershipsDetailPage from "../pages/(global-admin-routes)/dealership-detail/page";
import DealershipsPage from "../pages/(global-admin-routes)/dealerships/page";
import GlobalConfigurationsPage from "../pages/(global-admin-routes)/global-configurations/page";
import HeatMapPage from "../pages/(global-admin-routes)/heatmap/page";
import InventoryMappingPage from "../pages/(global-admin-routes)/inventory-mapping/page";
import GlobalLayoutClient from "../pages/(global-admin-routes)/layoutClient";
import TrainingVideosPage from "../pages/(global-admin-routes)/training/page";
import GlobalUsers from "../pages/(global-admin-routes)/users/page";
import ForgetPage from "../pages/auth/Forget/ForgetPage";
import ResetPage from "../pages/auth/Forget/ResetPage";
import AuthLayout from "../pages/auth/Layout";
import LoginPage from "../pages/auth/Login/LoginPage";
import RedirectPage from "../pages/auth/Login/RedirectPage";
import { roleChecker } from "../utils";
import useAxiosInterceptors from "../utils/axiosConfig";
import { useUserInfo } from "../utils/hooks/useUserInfo";
import { AuthenticatedRoutes } from "./AuthenticatedRoutes";
import { HomeRoute } from "./HomeRoute";
import { UnAuthenticatedRoutes } from "./UnAuthenticatedRoutes";
import { TinyUrlRedirect } from "../pages/(dashboard-routes)/TinyUrlRedirect";
import VideoViewerPage from "../pages/(global-admin-routes)/video-viewer/page";

const AppRoutes = () => {
  useAxiosInterceptors();
  const [tokenFetched, setTokenFetched] = useState(false);
  const token = useUserInfo("token");
  const role = useUserInfo("role");
  const user = useUserInfo("user");
  useEffect(() => {
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      setTokenFetched(true);
      if (roleChecker(role)) {
        axios.defaults.headers.common["Portal-Type"] = true;
      } else {
        axios.defaults.headers.common["Portal-Type"] = "";
      }

      //............add action to requests for activity log...............
      axios.interceptors.request.use((config: InternalAxiosRequestConfig) => {
        if (config.method === "patch" || config.method === "put") {
          config.headers["action"] = "Update";
        } else if (config.method === "post") {
          config.headers["action"] = "Create";
        } else if (config.method === "delete") {
          config.headers["action"] = "Delete";
        }
        return config;
      });
    } else {
      delete axios.defaults.headers.common["Authorization"];
      delete axios.defaults.headers.common["Portal-Type"];
    }
    setTokenFetched(true);
  }, [token]);
  if (!tokenFetched) {
    // Token is not yet fetched, you can render a loading spinner or something else
    return <div>Loading...</div>;
  }
  return (
    <Fragment>
      <Routes>
        <Route
          path="/vehicle"
          element={
            <LayoutClient>
              <VehicleDetail />
            </LayoutClient>
          }
        />
        <Route path="/tiny/:tinyUrl" element={<TinyUrlRedirect />} />

        <Route
          path="/guest_mode/dealership/:dId/contact/:id"
          element={
            <GuestModeLayoutClient>
              <GuestMode />
            </GuestModeLayoutClient>
          }
        />
        <Route
          path="/guest_mode/dealership/:dId/contact/:id/schedule_a_visit"
          element={
            <GuestModeLayoutClient>
              <ScheduleVisit />
            </GuestModeLayoutClient>
          }
        />
        <Route
          path="/guest_mode/dealership/:dId/contact/:id/my_profile"
          element={
            <GuestModeLayoutClient>
              <MyProfile />
            </GuestModeLayoutClient>
          }
        />
        <Route
          path="/guest_mode/dealership/:dId/contact/:id/apply_for_credit"
          element={
            <GuestModeLayoutClient>
              <ApplyForCredit />
            </GuestModeLayoutClient>
          }
        />
        <Route
          path="/guest_mode/dealership/:dId/contact/:id/my_garage"
          element={
            <GuestModeLayoutClient>
              <MyGarage />
            </GuestModeLayoutClient>
          }
        />
        <Route
          path="/guest_mode/dealership/:dId/contact/:id/my_document"
          element={
            <GuestModeLayoutClient>
              <GuestModeDocuments />
            </GuestModeLayoutClient>
          }
        />
        <Route
          path="/guest_mode/dealership/:dId/contact/:id/browse_inventory"
          element={
            <GuestModeLayoutClient>
              <BrowseInventory />
            </GuestModeLayoutClient>
          }
        />
        <Route
          path="/template-compose"
          element={
            <GlobalLayoutClient>
              <CreateTemplate />
            </GlobalLayoutClient>
          }
        />
        <Route element={<UnAuthenticatedRoutes />}>
          <Route
            path="/login"
            element={
              <AuthLayout>
                <LoginPage />
              </AuthLayout>
            }
          />
          <Route
            path="/redirect-url"
            element={
              <AuthLayout>
                <RedirectPage />
              </AuthLayout>
            }
          />
          <Route
            path="/forget-password"
            element={
              <AuthLayout>
                <ForgetPage />
              </AuthLayout>
            }
          />
          <Route
            path="/reset-password"
            element={
              <AuthLayout>
                <ResetPage />
              </AuthLayout>
            }
          />
        </Route>
        <Route element={<AuthenticatedRoutes />}>
          <Route path="/" element={<HomeRoute />} />
          <Route
            path="/leads"
            element={
              <LayoutClient>
                <LeadsPage />
              </LayoutClient>
            }
          />
          <Route
            path="/leads/lead-details"
            element={
              <LayoutClient>
                <LeadDetailsPage />
              </LayoutClient>
            }
          />
          <Route
            path="/leads/video-chat"
            element={
              <LayoutClient>
                <VideoChatPage />
              </LayoutClient>
            }
          />
          <Route
            path="/broadcast"
            element={
              <LayoutClient>
                <BroadcastMessagePage />
              </LayoutClient>
            }
          />
          <Route
            path="/broadcast/communication/:id"
            element={
              <LayoutClient>
                <CommunicationLogPage />
              </LayoutClient>
            }
          />

          <Route
            path="/rvm/communication/:id"
            element={
              <LayoutClient>
                <RVMCommunicationLogPage />
              </LayoutClient>
            }
          />
          <Route
            path="/emails/communication/:id"
            element={
              <LayoutClient>
                <EmailsCommunicationLogPage />
              </LayoutClient>
            }
          />
          <Route
            path="/power-dialer"
            element={
              <LayoutClient>
                <PowerDialerPage />
              </LayoutClient>
            }
          />
          <Route
            path="/not-found-user"
            element={
              <LayoutClient>
                <NotFound />
              </LayoutClient>
            }
          />
          <Route
            path="/power-dialer/:id"
            element={
              <LayoutClient>
                <PowerDialerDetailPage />
              </LayoutClient>
            }
          />
          <Route
            path="/power-dialer/communication/:id"
            element={
              <LayoutClient>
                <PwCommunicationLogPage />
              </LayoutClient>
            }
          />
          <Route
            path="/email-campaign"
            element={
              <LayoutClient>
                <EmailCampaignPage />
              </LayoutClient>
            }
          />
          <Route
            path="/bdc-campaign"
            element={
              <LayoutClient>
                <BDCCampaignPage />
              </LayoutClient>
            }
          />
          <Route
            path="/bdc-campaign/:option/:id"
            element={
              <LayoutClient>
                <BDCCampaignSettingsPage />
              </LayoutClient>
            }
          />
          <Route
            path="/global-bdc-campaign/:option/:id/:dealer_id"
            element={
              <GlobalLayoutClient>
                <BDCCampaignSettingsPage />
              </GlobalLayoutClient>
            }
          />
          <Route
            path="/global-bdc-campaign/:option/:id"
            element={
              <GlobalLayoutClient>
                <BDCCampaignSettingsPage />
              </GlobalLayoutClient>
            }
          />
          <Route
            path="/live-tracking"
            element={
              <LayoutClient>
                <LiveTracking />
              </LayoutClient>
            }
          />
          <Route
            path="/sarah"
            element={
              <LayoutClient>
                <SarahAIPage />
              </LayoutClient>
            }
          />
          <Route
            path="/meeting-calendar"
            element={
              <LayoutClient>
                <MeetingCalendarPage />
              </LayoutClient>
            }
          />
          <Route
            path="/dms-equity"
            element={
              <LayoutClient>
                <DMSEquityPage />
              </LayoutClient>
            }
          />
          <Route
            path="/digital-lead"
            element={
              <LayoutClient>
                <DigitalLeadPage />
              </LayoutClient>
            }
          />
          <Route
            path="/social-talk"
            element={
              <LayoutClient>
                <SocialTalk />
              </LayoutClient>
            }
          />
          <Route
            path="/email-blast"
            element={
              <LayoutClient>
                <EmailBlastPage />
              </LayoutClient>
            }
          />
          <Route
            path="/email-blast/template"
            element={
              <LayoutClient>
                <TemplatePage />
              </LayoutClient>
            }
          />
          <Route
            path="/email-blast/template-compose"
            element={
              <LayoutClient>
                <CreateTemplate />
              </LayoutClient>
            }
          />
          <Route
            path="/email-blast/compose"
            element={
              <LayoutClient>
                <ComposeBlastPage />
              </LayoutClient>
            }
          />
          <Route
            path="/training"
            element={
              <LayoutClient>
                <TraniningPage />
              </LayoutClient>
            }
          />
          <Route
            path="/tags"
            element={
              <LayoutClient>
                <TagPage />
              </LayoutClient>
            }
          />
          <Route
            path="/reports"
            element={
              <LayoutClient>
                <ReportsPage />
              </LayoutClient>
            }
          />

          <Route
            path="/desk-log"
            element={
              <LayoutClient>
                <DeskLog />
              </LayoutClient>
            }
          />

          <Route
            path="/auto-loan-calculator"
            element={
              <LayoutClient>
                <AutoLoanCalculator />
              </LayoutClient>
            }
          />

          <Route
            path="/users"
            element={
              <LayoutClient>
                <DPUsers />
              </LayoutClient>
            }
          />
          <Route
            path="/users/settings/:id"
            element={
              <LayoutClient>
                <UsersSettingPage />
              </LayoutClient>
            }
          />
          <Route
            path="/users/settings"
            element={
              <LayoutClient>
                <UsersSettingPage />
              </LayoutClient>
            }
          />
          <Route
            path="/dashboard"
            element={
              <GlobalLayoutClient>
                <Dashboard />
              </GlobalLayoutClient>
            }
          />
          <Route
            path="/announcements"
            element={
              <GlobalLayoutClient>
                <Announcements />
              </GlobalLayoutClient>
            }
          />
          <Route
            path="/announcements/compose"
            element={
              <GlobalLayoutClient>
                <ComposeBlast />
              </GlobalLayoutClient>
            }
          />
          <Route
            path="/announcements/communication/:id"
            element={
              <GlobalLayoutClient>
                <CommunicationLog />
              </GlobalLayoutClient>
            }
          />
          <Route
            path="/heatmap"
            element={
              <GlobalLayoutClient>
                <HeatMapPage />
              </GlobalLayoutClient>
            }
          />
          <Route
            path="/dealerships/:id"
            element={
              <GlobalLayoutClient>
                <DealershipsDetailPage />
              </GlobalLayoutClient>
            }
          />
          <Route
            path="/dealerships/lines-ring-groups/line-settings/:dealerId/:id"
            element={
              <GlobalLayoutClient>
                <LineSettingsGroup />
              </GlobalLayoutClient>
            }
          />
          <Route
            path="/dealerships/lines-ring-groups/create-ring-group/:id"
            element={
              <GlobalLayoutClient>
                <CreateRingGroupPage />
              </GlobalLayoutClient>
            }
          />
          <Route path="/not-found" element={<NotFound />} />
          <Route path="/forbidden" element={<ForbiddenPage />} />
          <Route
            path="/dealerships/lines-ring-groups/create-ring-group/:id/:ringId"
            element={
              <GlobalLayoutClient>
                <CreateRingGroupPage />
              </GlobalLayoutClient>
            }
          />
          <Route
            path="/admin/settings"
            element={
              <GlobalLayoutClient>
                <UsersSettingPage />
              </GlobalLayoutClient>
            }
          />
          <Route
            path="/admin/settings/:id"
            element={
              <GlobalLayoutClient>
                <UsersSettingPage />
              </GlobalLayoutClient>
            }
          />
          <Route
            path="/dealerships"
            element={
              <GlobalLayoutClient>
                <DealershipsPage />
              </GlobalLayoutClient>
            }
          />
          <Route
            path="/global-users"
            element={
              <GlobalLayoutClient>
                <GlobalUsers />
              </GlobalLayoutClient>
            }
          />
          <Route
            path="/activity-log"
            element={
              <GlobalLayoutClient>
                <ActivityLogPage />
              </GlobalLayoutClient>
            }
          />
          <Route
            path="/global-configurations"
            element={
              <GlobalLayoutClient>
                <GlobalConfigurationsPage />
              </GlobalLayoutClient>
            }
          />
          <Route
            path="/campaign-registeration"
            element={
              <GlobalLayoutClient>
                <CampaignRegisterationPage />
              </GlobalLayoutClient>
            }
          />
          <Route
            path="/billing"
            element={
              <GlobalLayoutClient>
                <BillingPage />
              </GlobalLayoutClient>
            }
          />
          <Route
            path="/inventory-mapping"
            element={
              <GlobalLayoutClient>
                <InventoryMappingPage />
              </GlobalLayoutClient>
            }
          />
          <Route
            path="/training/:id"
            element={
              <GlobalLayoutClient>
                <TrainingVideosPage />
              </GlobalLayoutClient>
            }
          />
        </Route>
        <Route path="/video/:url" element={<VideoViewerPage />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Fragment>
  );
};

export default AppRoutes;
