import React from "react";
import { Drawer } from "../../../components/common";
import UserListing from "./UserListing";

interface UserListingDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  users: any[]; // Define a proper user type
  setPage: any;
  removedUsers: any[]; // Define a proper type for removed users
  setRemovedUsers: React.Dispatch<React.SetStateAction<any[]>>;
  type: any;
  formVals: any;
}

const UserListingDrawer: React.FC<UserListingDrawerProps> = ({
  isOpen,
  onClose,
  users,
  setPage,
  removedUsers,
  setRemovedUsers,
  type,
  formVals,
}) => (
  <Drawer isOpen={isOpen} onClose={onClose} title="Contacts">
    <div
      id="scrollableDiv"
      style={{ height: "calc(100vh - 0px)", overflow: "auto" }}
    >
      <UserListing
        setPage={setPage}
        users={users}
        count={Number(formVals?.users)} // Make sure to define formVals properly
        setRemovedUsers={setRemovedUsers}
        removedUsers={removedUsers}
        type={type}
      />
    </div>
  </Drawer>
);

export default UserListingDrawer;
