import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { ActionCableContext } from "../../../../../../App";
import { useUserInfo } from "../../../../../../utils/hooks/useUserInfo";
import { useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import {
  sendSMS,
  translateMsg,
} from "../../../../../../utils/api/communication.api";
import { uploadSmsImage } from "../../../../../../utils/api/lead.api";
import { useTwilioState } from "../../../../../../utils/context/TwilioContext";
import useInfiniteScroll, {
  ScrollDirection,
} from "react-easy-infinite-scroll-hook";
import moment from "moment";
import { getThreadMessages } from "../../../../../../utils/api/history.api";
import { filterByUniqueKey } from "../../../../../../utils";
import { markMessageAsRead } from "../../../../../../utils/api/header-notifications.api";

type useMessageThreadTypes = {
  clickedRecord: any;
  clickedRow: any;
  type: string;
  activeIndex?: number;
};

export const useMessageThread = ({
  clickedRecord,
  clickedRow,
  activeIndex,
}: useMessageThreadTypes) => {
  const [thread, setMessageThread] = useState<any>({});
  const [initialLoad, setInitialLoad] = useState(false);
  const [attachments, setAttachments] = useState<string[]>([]);
  const [device, setDevice] = useState("");
  const [messageBody, setMessageBody] = useState("");
  const [mentions, setMentions] = useState<any>([]);
  const [translate, setTranslate] = useState<any>({});
  const [translatedArray, setTranslatedArray] = useState<any>([]);
  const perPage = 10;
  const { cable } = useContext(ActionCableContext);

  const subscriptionRef = useRef<any>(null); // Use ref to store the subscription

  const user = useUserInfo("user");
  const dealership = user?.dealership;

  const toast = useToast();

  const mutation = useMutation<any, Error, any, unknown>({
    mutationFn: async (payload) => {
      try {
        const response = await sendSMS(payload);
        return { data: response.data };
      } catch (error) {
        throw error;
      }
    },
    onSuccess: () => {
      toast({
        description: `${
          mentions?.length ? "Ping" : "Message"
        } Sent Successfully`,
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      setMentions([]);
    },
    onError: (error: any) => {
      toast({
        description: `Error Sending ${mentions?.length ? "Ping" : "Message"}: ${
          error?.response?.data?.errors?.toString() ?? "Something went wrong"
        }`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
  });

  const mutationTranslate = useMutation<any, Error, any, { payload: any }>({
    mutationFn: async (payload) => {
      try {
        const response = await translateMsg(payload);
        return { data: response.data };
      } catch (error) {
        throw error;
      }
    },
    onMutate: (payload) => {
      return { payload };
    },
    onSuccess: (data, variables, context) => {
      setTranslatedArray((prev: any) => [
        ...prev,
        {
          id: context?.payload.id,
          translated: data?.data?.translated_text,
          language: translate?.language,
        },
      ]);
    },
    onError: (error: any) => {
      toast({
        description: `Error Translating ${
          mentions?.length ? "Ping" : "Message"
        }: ${
          error?.response?.data?.errors?.toString() ?? "Something went wrong"
        }`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
  });
  const translateMessage = async (lang: any) => {
    try {
      await mutationTranslate.mutateAsync({
        id: translate?.id,
        payload: {
          text: translate?.content,
          language: lang,
        },
      });
    } catch (error) {}
  };

  const attachmentMutation = useMutation<any, Error, any, unknown>({
    mutationFn: async (payload) => {
      try {
        const response = await uploadSmsImage({ payload });
        setAttachments((value) => [...value, response.data]);
        return response.data.url;
      } catch (error) {
        throw error;
      }
    },
  });

  const { created_at: selectedDate } = clickedRecord || {};
  const { id: contactId } = clickedRow || {};

  const { devices } = useTwilioState();
  const lineDevices = filterByUniqueKey(
    devices.filter((device) => device.phoneNumber),
    "phoneNumber"
  );

  const { messages = [] } = thread || {};

  const divRefCurrent = useRef<HTMLDivElement>(null);

  const sendMessage = async () => {
    try {
      await mutation.mutateAsync({
        to: clickedRow?.phone_number,
        from: device,
        contact_id: clickedRow.id,
        content: messageBody,
        dealership_id: user.dealership.id,
        user_id: user.id,
        media_urls: attachments.map((attachment: any) => attachment.url),
        user_ids: mentions.filter(function (item: number, pos: number) {
          return mentions.indexOf(item) === pos;
        }),
        ping: !!mentions.length,
      });
    } catch (error) {}

    setAttachments([]);
    setMessageBody("");

    await setThreadInfo(moment().toISOString(), 1, false, true);
  };

  const messageMutation = useMutation(markMessageAsRead);

  useEffect(() => {
    try {
      if (!cable) return;
      const subscription = cable.subscriptions.create(
        {
          channel: "MessageChannel",
          contact_id: clickedRow.id,
          dealership_id: dealership.id,
        },
        {
          connected: () => {
            console.log("message connected");
          },
          disconnected: () => {
            console.log("disconnected");
          },
          received: async (data: any) => {
            if (!document.getElementById('message-container')) return;
            console.log("subscriptionRef.current", subscriptionRef.current)
            // Handle incoming data from Action Cable
            const messagePayload = JSON.parse(data?.message);
            setMessageThread({});
            setAttachments([]);
            setMessageBody("");

            messageMutation.mutate({
              dId: dealership?.id,
              id: messagePayload?.id,
            });

            await setThreadInfo(moment().toISOString(), 1, false, true);
          },
        }
      );

      // Store the subscription in the ref
      subscriptionRef.current = subscription;
    } catch (error) {}

    // Cleanup the subscription when the component unmounts
    return () => {
      if (subscriptionRef.current) {
        subscriptionRef.current.unsubscribe();
        subscriptionRef.current = null; // Mark unmounted
        console.log("Unsubscribed from ActionCable on unmount");
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cable, clickedRow, dealership]);

  const handleUploadDocuments = async (e: any) => {
    if (e.target.files) {
      const file = e.target.files[0];
      if (file.size / 1024 / 1024 > 20) {
        return toast({
          description: "Only files less than 20mb are acceptable.",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
      }

      const formData = new FormData();
      formData.append("file", file);
      formData.append("dealership_id", dealership.id);
      e.target.value = "";

      try {
        // You can write the URL of your server or any other endpoint used for file upload
        attachmentMutation.mutate(formData);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const dateFormatter = (date: string) => {
    return moment(date).format("MM-DD-YYYY");
  };

  const arrayFormatter = (arr: any, value: number) => {
    if (Array.isArray(arr)) {
      const transformedArr = [...arr, value];
      return transformedArr;
    }
    return [value];
  };

  const setLoadedThreadInfo = (
    currentDate: string,
    messagesThread: any,
    prev: any
  ) => {
    const transformedMessageThread = threadPagination(messagesThread);
    return {
      ...prev,
      [dateFormatter(currentDate)]: {
        ...transformedMessageThread,
        pagination: {
          ...transformedMessageThread.pagination,
          current_page: arrayFormatter(
            prev[dateFormatter(currentDate)]?.pagination?.current_page,
            transformedMessageThread.pagination.current_page
          ),
        },
      },
    };
  };

  const threadPagination = (messageThread: any) => {
    const { messages, ...paginationInfo } = messageThread;
    return paginationInfo;
  };

  const shouldFetchMessages = (date: string, page: number) => {
    const { loadedThreads } = thread || {};
    const { current_page } =
      loadedThreads[dateFormatter(date)]?.pagination || {};
    return !current_page?.includes(page);
  };

  const setThreadInfo = async (
    date: string,
    page: number,
    next = true,
    reset = false
  ) => {
    // setCounter(prev=>prev+1)
    const messagesThread = await generateMessageThread(
      date,
      contactId,
      page,
      perPage
    );

    const updatedLoadedThreads = setLoadedThreadInfo(
      date,
      messagesThread,
      thread.loadedThreads || {}
    );

    const threadOfMessages = {
      loadedThreads: updatedLoadedThreads,
      ...messagesThread,
      messages: reset
        ? [...messagesThread.messages].reverse()
        : next
        ? [
            ...(thread.messages || []),
            ...[...(messagesThread.messages || [])].reverse(),
          ]
        : [
            ...[...(messagesThread.messages || [])].reverse(),
            ...(thread.messages || []),
          ],
    };

    setMessageThread(threadOfMessages);

    return messagesThread;
  };

  const getLoadedThread = (date: string) => {
    const { loadedThreads } = thread || {};
    return loadedThreads[dateFormatter(date)];
  };

  const getMessagesOfDate = (date: string) => {
    return messages.filter(
      (message: any) =>
        dateFormatter(message.created_at) === dateFormatter(date)
    ).length;
  };

  const next = async (direction: ScrollDirection) => {
    if (!messages.length || !document.getElementById("message-container"))
      return;
    if (!initialLoad) {
      const rect = divRefCurrent?.current?.getBoundingClientRect();
      const { top = 0, bottom = 0 } = rect || {};
      const isInView = top < window.innerHeight && bottom >= 0;
      if (!isInView) {
        divRefCurrent.current?.scrollIntoView({
          behavior: "smooth",
        });
      }
      setInitialLoad(true);
      return;
    }

    if (direction === "up") {
      const currentDate = messages?.[0]?.created_at;
      const { pagination, prev_date } = getLoadedThread(currentDate) || {};
      const { total_pages, current_page } = pagination || {};
      const currentPage = current_page?.[current_page?.length - 1];
      const { messages_count, date } = prev_date || {};
      if (total_pages <= currentPage && !messages_count) return;
      if (total_pages <= currentPage && messages_count) {
        const formattedDate = date;
        if (shouldFetchMessages(formattedDate, 1)) {
          await setThreadInfo(formattedDate, 1, false);
        }

        return;
      }
      if (shouldFetchMessages(currentDate, currentPage + 1)) {
        return await setThreadInfo(currentDate, currentPage + 1, false);
      }
    } else {
      const currentDate = messages?.[messages.length - 1]?.created_at;
      const { pagination, next_date } = getLoadedThread(currentDate) || {};
      const { current_page, total_count } = pagination || {};
      const currentPage = current_page[0];
      const { messages_count, date } = next_date || {};
      const messagesLength = getMessagesOfDate(currentDate);
      if (
        (currentPage <= 1 || messagesLength >= total_count) &&
        !messages_count
      )
        return;
      if (
        (currentPage <= 1 || messagesLength >= total_count) &&
        messages_count
      ) {
        const lastPage = Math.ceil(messages_count / perPage);
        const formattedDate = date;
        if (shouldFetchMessages(formattedDate, lastPage))
          await setThreadInfo(formattedDate, lastPage);
        return;
      }
      if (shouldFetchMessages(currentDate, currentPage - 1))
        await setThreadInfo(currentDate, currentPage - 1);
    }
  };

  const ref = useInfiniteScroll({
    next: (direction) =>
      document.getElementById("message-container")
        ? next(direction)
        : (null as any),
    rowCount: messages.length,
    hasMore: { down: true, up: true },
  });

  const generateMessageThread = async (
    selectedDate: string,
    contactId: string,
    page = 1,
    perPage = 10
  ) => {
    const res = await getThreadMessages({
      date: selectedDate,
      contactId: contactId,
      page: page,
      perPage: perPage,
      dealershipId: dealership.id,
    });
    const { data: messagesThread } = res || {};
    return messagesThread;
  };

  const fetchMessages = useCallback(async () => {
    if (!contactId) return;
    if (
      !(
        (activeIndex === 2 && clickedRecord) ||
        clickedRecord?.record_type === "message"
      )
    )
      return;
    const messagesThread: any = await generateMessageThread(
      selectedDate,
      contactId
    );
    setMessageThread((prev: any) => ({
      ...prev,
      ...messagesThread,
      loadedThreads: setLoadedThreadInfo(
        selectedDate,
        messagesThread,
        prev.loadedThreads || {}
      ),
      messages: [...[...messagesThread.messages].reverse()],
    }));

    const defaultLine =
      lineDevices?.find((line: any) => line.is_default)?.phoneNumber ||
      lineDevices?.[0]?.phoneNumber;
    setDevice(defaultLine || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex, clickedRecord, contactId]);

  useEffect(() => {
    if (!contactId) return;
    if (
      !(
        (activeIndex === 2 && clickedRecord) ||
        clickedRecord?.record_type === "message"
      )
    )
      return;
    fetchMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex, clickedRecord, contactId]);

  return {
    ref,
    thread,
    handleUploadDocuments,
    sendMessage,
    lineDevices,
    attachmentMutation,
    dateFormatter,
    divRefCurrent,
    device,
    setDevice,
    attachments,
    setAttachments,
    messageBody,
    setMessageBody,
    mutation,
    next,
    setMessageThread,
    setInitialLoad,
    mentions,
    setMentions,
    translate,
    setTranslate,
    translateMessage,
    translatedArray,
    setTranslatedArray,
  };
};
