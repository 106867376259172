import { Box, HStack, Text, VStack } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { humanize } from "../../../utils";
import { fetchBillingSummary } from "../../../utils/api/usage";
import { Input, PaginatedTable } from "../../common";
import Loader from "../../common/Spinner";
import { SearchIcon } from "../../icons/svg";
import moment from "moment";
import DatePicker from "../../common/DateRangePicker";

const normalizeAndSort = (data: any) => {
  const normalizedData: any = {};

  data.forEach((item: any) => {
    // Determine if item is in the form of an object with "id", "value", and "label"
    if ("label" in item) {
      const key = item.label.toLowerCase();
      if (!normalizedData[key]) {
        normalizedData[key] = {
          id: item.id,
          value: item.value,
          label: item.label,
        };
      }
    } else {
      // Handle the case where item is a simple key-value pair
      const key = Object.keys(item)[0].toLowerCase();
      if (!normalizedData[key]) {
        normalizedData[key] = item;
      }
    }
  });

  // Convert back to an array
  const uniqueData = Object.values(normalizedData);

  // Sort the unique data
  const sortKey = (item: any) => {
    const key = item.label
      ? item.label.toLowerCase()
      : Object.keys(item)[0].toLowerCase();
    const order = ["sms", "mms", "calls", "phone", "a2p"];

    for (let i = 0; i < order.length; i++) {
      if (key.startsWith(order[i])) {
        return i;
      }
    }
    return order.length;
  };

  const sortedData = uniqueData.sort((a: any, b: any) => {
    const keyA = sortKey(a);
    const keyB = sortKey(b);

    if (keyA === keyB) {
      // If they belong to the same group, sort them alphabetically by key
      const nameA = a.label
        ? a.label.toLowerCase()
        : Object.keys(a)[0].toLowerCase();
      const nameB = b.label
        ? b.label.toLowerCase()
        : Object.keys(b)[0].toLowerCase();
      return nameA.localeCompare(nameB);
    }

    return keyA - keyB;
  });

  return sortedData;
};

function UsageInfo() {
  const [selectionRange, setSelectionRange] = useState<any>({
    startDate: "",
    endDate: new Date(),
    key: "selection",
    selected: false,
  });
  const [searchStr, setSearchStr] = useState("");
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(10);

  const { data, isLoading } = useQuery({
    queryKey: ["brandsApi", page, perPage, searchStr, selectionRange],
    queryFn: () => {
      const params: any = {};
      if (selectionRange && selectionRange?.selected) {
        if (selectionRange?.startDate)
          params.start_time = selectionRange?.startDate.toString();
        if (selectionRange?.endDate)
          params.end_time = selectionRange?.endDate.toString();
      }
      if (!searchStr) {
        params.page = page;
        params.per_page = perPage;
      } else {
        params.q = searchStr;
      }

      return fetchBillingSummary(params);
    },
    refetchOnWindowFocus: false,
    retry: false,
  });

  console.log("data", data);

  const activeHeader = useMemo(() => {
    if (!data?.data.dealerships?.length) {
      return [];
    }

    const { dealership_id, dealership_name, ...remainingKeys } =
      data?.data.dealerships?.[0] || {};

    let headers = Object.keys(remainingKeys).reduce<any[]>(
      (acc, key, index) => {
        const transformedCostKey = `${key.replaceAll("-", "_")}_cost`;
        const transformedUsageKey = `${key.replaceAll("-", "_")}_usage`;
        acc.push(
          ...[
            {
              id: index + 2,
              value: humanize(transformedCostKey, false),
              label: transformedCostKey,
            },
            {
              id: index + 3,
              value: humanize(transformedUsageKey, false),
              label: transformedUsageKey,
            },
          ]
        );

        return acc;
      },
      []
    );

    headers = normalizeAndSort(headers);

    return [{ id: 1, value: "Dealership Name", label: "name" }, ...headers];
  }, [data?.data.dealerships]);

  const activeData = useMemo(() => {
    if (!data?.data.dealerships?.length) {
      return [];
    }

    return data?.data.dealerships?.map((dealership: any) => {
      const { dealership_name, dealership_id, ...remainingKeys } =
        dealership || {};
      let headers = Object.keys(remainingKeys).reduce<any[]>(
        (acc, key, index) => {
          const { total_cost, total_count } = remainingKeys[key];
          const transformedCostKey = `${key.replaceAll("-", "_")}_cost`;
          const transformedUsageKey = `${key.replaceAll("-", "_")}_usage`;
          acc.push(
            ...[
              {
                [transformedCostKey]: `${Number(total_cost).toFixed(2)} $`,
              },
              {
                [transformedUsageKey]: total_count,
              },
            ]
          );

          return acc;
        },
        []
      );

      headers = normalizeAndSort(headers);

      return [{ name: dealership_name }, ...headers].reduce((acc, obj) => {
        return { ...acc, ...obj };
      }, {});
    });
  }, [data?.data.dealerships]);

  return (
    <>
      {isLoading && <Loader />}
      <VStack
        w="100%"
        alignItems="flex-start"
        gap="0"
        overflow="auto"
        h="calc(100vh - 172px)"
      >
        <HStack
          w="100%"
          justifyContent="space-between"
          padding="1.25rem 1rem"
          background="white"
        >
          <HStack gap="0.625rem" w="100%" justifyContent="flex-start">
            <Input
              maxW="20rem"
              type="text"
              hasIcon={true}
              isIconRight={false}
              icon={<SearchIcon />}
              placeholder="Search Dealerships..."
              onChange={({ target: { value } }) => setSearchStr(value)}
              value={searchStr}
            />
          </HStack>
        </HStack>
        <VStack w="100%" alignItems="flex-start">
          <Box w="100%" alignItems="flex-start" background="white">
            <Text
              textStyle="caption"
              padding="1rem 1rem 0.75rem"
              w="100%"
              color="var(--grey-600)"
            >
              Total Dealerships ({data?.data?.total_count || 0})
            </Text>

            <HStack
              w="100%"
              justifyContent="flex-start"
              alignItems="center"
              padding="1rem 1rem 0.75rem"
            >
              <DatePicker
                value={selectionRange}
                onChange={(value) => {
                  setSelectionRange(value);
                }}
                shouldShowClear
                label="Select Date Range"
                shouldShowLabel={false}
              />
              <Text textStyle="h6" color="var(--red-600)">
                Last Updated at:{" "}
                <i style={{ color: "var(--grey-900)" }}>
                  {moment(data?.data?.updated_at).format("MM-DD-YYYY hh:mm A")}
                </i>
              </Text>
            </HStack>

            <VStack
              overflowX="auto"
              // maxW="1370px"
              // maxW={{ base: "100%", xl: "1370px", "2xl": "1600px" }}
              w="100%"
              className="usageInfoTable"
            >
              <PaginatedTable
                header={activeHeader}
                data={activeData}
                itemsPerPage={perPage}
                maxPageNumbers={5}
                isPaginated={true}
                lastCenter={false}
                hasMultiBody={false}
                perPageChange={(value) => {
                  setPerPage(Number(value?.label));
                }}
                currentPage={page}
                setCurrentPage={setPage}
                rowCount={data?.data?.total_count || 0}
              />
            </VStack>
          </Box>
        </VStack>
      </VStack>
    </>
  );
}

export default UsageInfo;
