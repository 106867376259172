import {
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  HStack,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { CloseIcon } from "../../icons/svg";
import "./EmailDrawer.scss";

import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { RichTextEditorContainers } from "../../../config";
import { sendEmail } from "../../../utils/api/communication.api";
import { uploadImage } from "../../../utils/api/lead.api";
import { useHistoryDispatch } from "../../../utils/context/HistoryContext";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";
import { Input, TagsInput } from "../../common";
import AttachmentViewer from "../../common/AttachmentViewer";
import Loader from "../../common/Spinner";
import TextEditor from "../../common/TextEditor";
import ScheduleModal from "../SmsDrawer/ScheduleModal";
import { SendEmailForm, defaultValues, sendEmailFormValidation } from "./utils";
import AttachmentBar from "../../AttachmentBar";

type EmailDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  clickedRow?: any;
  userEmail?: string;
};

function EmailDrawer({
  isOpen,
  onClose,
  clickedRow,
  userEmail,
}: EmailDrawerProps) {
  const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false);
  const [attachments, setAttachments] = useState<string[]>([]);

  const editorRef = useRef();

  const user = useUserInfo("user");
  const dealership = user?.dealership;

  const { handleSubmit, control, setValue, getValues, trigger } =
    useForm<SendEmailForm>({
      defaultValues: defaultValues,
      resolver: yupResolver(sendEmailFormValidation) as any,
    });

  const ccField = useWatch({ control, name: "cc" });

  const toast = useToast();
  const dispatch = useHistoryDispatch();

  useEffect(() => {
    if (clickedRow) {
      setValue("to", clickedRow.email);
    }
  }, [clickedRow, setValue]);

  useEffect(() => {
    if (userEmail) {
      setValue("from", userEmail);
    }
  }, [setValue, userEmail]);

  const [shouldShowCCFields, setShouldShowCCFields] = useState(false);

  const mutation = useMutation<any, Error, any, unknown>({
    mutationFn: async (payload) => {
      try {
        const response = await sendEmail({
          payload,
          dealershipId: user?.dealership?.id,
        });
        return { data: response.data };
      } catch (error) {
        throw error;
      }
    },
    onSuccess: () => {
      toast({
        description: "Email sent successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      onClose();
      dispatch({
        type: "SET_SHOULD_REFETCH",
        payload: { shouldRefetch: true },
      });
    },
    onError: (error: any) => {
      toast({
        description: `Error Sending Email: ${
          error?.response?.data?.errors?.toString() ?? "Something went wrong"
        }`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
  });

  const handleFormSubmit = (values: SendEmailForm) => {
    const payload = {
      email_log: {
        from_address: userEmail,
        to_address: values.to,
        cc_address: values.cc
          .filter((ccValue: any) => ccValue.value !== values.to)
          .map((ccValue: any) => ccValue.value)
          .join(","),
        subject: values.subject,
        body: values.messageBody,
        internal_action: "outbound",
        contact_id: clickedRow?.id,
        attachment_urls: attachments.map((attachment: any) => attachment.url),
      },
    };

    mutation.mutate(payload);
  };

  const handleSchedule = (date: Date) => {
    const values = getValues();
    mutation.mutate({
      from_address: userEmail,
      to_address: values.to,
      cc_address: values.cc
        .filter((ccValue: any) => ccValue.value !== values.to)
        .map((ccValue: any) => ccValue.value)
        .join(","),
      subject: values.subject,
      body: values.messageBody,
      internal_action: "outbound",
      contact_id: clickedRow?.id,
      attachment_urls: attachments.map((attachment: any) => attachment.url),
      is_scheduled: true,
      date_scheduled: date.toUTCString(),
    });
  };

  const attachmentMutation = useMutation<any, Error, any, unknown>({
    mutationFn: async (payload) => {
      try {
        const response = await uploadImage({
          payload,
          dealershipId: dealership.id,
        });
        setAttachments((value) => [...value, response.data]);
        return response.data.url;
      } catch (error) {
        throw error;
      }
    },
  });

  const handleUploadDocuments = async (e: any) => {
    if (e.target.files) {
      const file = e.target.files[0];
      if (file.size / 1024 / 1024 > 20) {
        return toast({
          description: "Only files less than 20mb are acceptable.",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
      }

      const formData = new FormData();
      formData.append("file", file);
      e.target.value = "";

      try {
        // You can write the URL of your server or any other endpoint used for file upload
        attachmentMutation.mutate(formData);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleSetBody = (
    text: string,
    concatText?: string,
    type = "text",
    isVariable = false
  ) => {
    const editor = (editorRef.current as any)?.current?.getEditor();
    const range = editor?.getSelection(true);
    if (isVariable) {
      editor.insertText(range.index, text, "user");
    } else if (type === "text") {
      editor.insertText(range.index, concatText, "user");
      editor.setSelection(range.index, concatText?.length || 0);
      editor.theme.tooltip.edit(
        "link",
        text.indexOf("://") === -1 ? "http://" + text : text
      );
      editor.theme.tooltip.save();
      editor.setSelection(range.index + concatText?.length || 0);
    } else if (type === "emoji") {
      if (range) {
        const cursorPosition = range.index;

        // Insert the emoji at the current cursor position
        editor.insertText(cursorPosition, text);

        // Move the cursor to the end of the inserted emoji
        editor.setSelection(cursorPosition + text.length, 0);
      }
    } else if (type === "inline-attachment") {
      const url = (text as any).url;
      editor.insertEmbed(
        range.index,
        "image",
        url.indexOf("://") === -1 ? "http://" + url : url
      );
    } else {
      setAttachments((prev: any) => [...prev, text]);
    }
  };

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      placement="bottom"
      id="email-drawer"
    >
      <DrawerContent
        padding="0"
        borderTopRightRadius="0.5rem"
        borderTopLeftRadius="0.5rem"
      >
        <DrawerBody padding="0">
          {(attachmentMutation.isLoading || mutation.isLoading) && <Loader />}
          <VStack w="100%" alignItems="flex-start" gap="0">
            <HStack
              justifyContent="space-between"
              w="100%"
              background="var(--secondary-600)"
              borderTopRightRadius="0.5rem"
              borderTopLeftRadius="0.5rem"
              padding="0.69rem 1rem"
            >
              <Text color="white" textStyle="h5" fontWeight="600">
                Email Center - Your SimpSocial Email: {userEmail}
              </Text>
              <HStack>
                <Button
                  variant="none"
                  padding="0.38rem"
                  border="1px solid var(--grey-300)"
                  borderRadius="0.5rem"
                  onClick={onClose}
                  sx={{
                    svg: {
                      path: {
                        fill: "white",
                      },
                    },
                  }}
                >
                  <CloseIcon />
                </Button>
              </HStack>
            </HStack>
            <VStack w="100%" alignItems="flex-start" gap="0">
              <HStack
                w="100%"
                padding="0 1rem"
                gap="0"
                borderTop="1px solid var(--grey-300)"
              >
                <Text
                  textStyle="h6"
                  fontWeight="600"
                  w="20%"
                  color=" var(--grey-500)"
                >
                  To
                </Text>
                <Controller
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => {
                    return (
                      <>
                        <Input
                          border="0"
                          borderLeft="1px solid var(--grey-300)"
                          borderRadius="0"
                          label=""
                          value={value}
                          onChange={onChange}
                          error={error?.message}
                          isRequired
                          borderBottom={
                            error?.message ? "1px solid var(--grey-300)" : "0"
                          }
                          errorStyle={{
                            borderLeft: "1px solid var(--grey-300)",
                          }}
                        />
                        <span style={{ fontSize: 20, fontWeight: "bolder" }}>
                          {!shouldShowCCFields
                            ? ccField?.length
                              ? `+${ccField.length}`
                              : ""
                            : ""}
                        </span>
                      </>
                    );
                  }}
                  name="to"
                  control={control}
                  rules={{
                    required: true,
                  }}
                />
              </HStack>
              <>
                <HStack
                  w="100%"
                  padding="0 1rem"
                  gap="0"
                  borderTop="1px solid var(--grey-300)"
                >
                  <Text
                    textStyle="h6"
                    fontWeight="600"
                    w="20%"
                    color=" var(--grey-500)"
                  >
                    Cc
                  </Text>
                  <Controller
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => {
                      return (
                        <TagsInput
                          checkDuplicate
                          placeholder="Enter Email"
                          label=""
                          border="0"
                          borderLeft="1px solid var(--grey-300)"
                          pillStyles={{
                            // borderLeft: "1px solid var(--grey-300)",
                            paddingLeft: 4,
                            paddingBottom: 4,
                          }}
                          borderRadius="0"
                          type="email"
                          value={value as any}
                          onChange={onChange}
                          error={error?.message}
                          onAdd={async (newValue: string) => {
                            setValue("cc", [
                              ...value,
                              { id: Date.now(), value: newValue } as any,
                            ]);
                          }}
                          onRemove={(id: number) =>
                            setValue(
                              "cc",
                              value.filter((value: any) => value.id !== id)
                            )
                          }
                        />
                      );
                    }}
                    name="cc"
                    control={control}
                    rules={{
                      required: true,
                    }}
                  />
                </HStack>
              </>
              <HStack
                w="100%"
                padding="0 1rem"
                gap="0"
                borderTop="1px solid var(--grey-300)"
              >
                <Text
                  textStyle="h6"
                  fontWeight="600"
                  w="20%"
                  color=" var(--grey-500)"
                >
                  Subject
                </Text>
                <Controller
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => {
                    return (
                      <Input
                        border="0"
                        borderLeft="1px solid var(--grey-300)"
                        borderRadius="0"
                        label=""
                        value={value}
                        onChange={onChange}
                        error={error?.message}
                        isRequired
                        borderBottom={
                          error?.message ? "1px solid var(--grey-300)" : "0"
                        }
                        errorStyle={{
                          borderLeft: "1px solid var(--grey-300)",
                        }}
                      />
                    );
                  }}
                  name="subject"
                  control={control}
                  rules={{
                    required: true,
                  }}
                />
              </HStack>
              <AttachmentBar
                setBody={handleSetBody}
                type="email"
                contactId={clickedRow?.uuid}
                handleTemplate={(value, body = "", attachmentUrls) => {
                  setValue("subject", value);
                  setValue("messageBody", body);
                  setAttachments(attachmentUrls);
                }}
              />
              <Controller
                render={({ field: { value }, fieldState: { error } }) => {
                  return (
                    <div
                      style={{ width: "100%", overflow: "auto" }}
                      className="emailComposeWrapper dmsEquityMiningEditor"
                    >
                      <TextEditor
                        value={value}
                        onChange={(value) => setValue("messageBody", value)}
                        error={error?.message}
                        style={{ height: "18rem" }}
                        toolbar={RichTextEditorContainers}
                        editorRef={editorRef}
                      />
                    </div>
                  );
                }}
                name="messageBody"
                control={control}
                rules={{
                  required: true,
                }}
              />

              <AttachmentViewer
                attachments={attachments}
                setAttachments={setAttachments}
              />
              <HStack w="100%" padding="1rem" background="var(--grey-200)">
                <Button
                  variant="outline"
                  flex="1"
                  onClick={async () => {
                    const isValid = await trigger();

                    isValid && setIsScheduleModalOpen(true);
                  }}
                >
                  <HStack>
                    <Text>Schedule</Text>
                  </HStack>
                </Button>
                <Button
                  flex="1"
                  onClick={() =>
                    handleSubmit((values) => handleFormSubmit(values))()
                  }
                >
                  Send Now
                </Button>
              </HStack>
            </VStack>
          </VStack>
        </DrawerBody>
      </DrawerContent>

      <ScheduleModal
        isOpen={isScheduleModalOpen}
        onClose={() => setIsScheduleModalOpen(false)}
        handleSchedule={handleSchedule}
      />
    </Drawer>
  );
}

export default EmailDrawer;
