import { Box, HStack, Image, Text, VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Input, PaginatedTable } from "../../common";
import { SearchIcon } from "../../../components/icons/svg";
import { useQuery } from "@tanstack/react-query";
import { getCompletedMapping } from "../../../utils/api/inventory-mapping.api";
import Loader from "../../common/Spinner";
import moment from "moment";
import TooltipIfOverflow from "../../common/TooltipIfOverflow";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

const InventoryCompleted = () => {
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(20);
  const [searchStr, setSearchStr] = useState("");
  const [count, setCount] = useState<number>(0);

  const { data, isLoading } = useQuery({
    queryKey: ["completedApi", page, perPage, searchStr],
    queryFn: () => {
      const params: any = {};
      if (!searchStr) {
        params.page = page;
        params.per_page = perPage;
      } else {
        params.q = searchStr;
      }

      return getCompletedMapping(params);
    },
    refetchOnWindowFocus: false,
    retry: false,
  });

  useEffect(() => {
    setCount(data?.data?.response[0]?.count ?? 0);
  }, [data]);

  const activeHeader = [
    { id: 1, value: "Name", label: "name" },
    { id: 3, value: "Received At", label: "receivedAt" },
    { id: 4, value: "Received From", label: "receivedFrom" },
    { id: 5, value: "Total Cars", label: "totalCars" },
    { id: 6, value: "Total used", label: "totalUsed" },
    { id: 7, value: "Total New", label: "totalNew" },
  ];

  const activeData = data?.data?.response[0]?.data?.map((item: any) => ({
    name: (
      <HStack gap="1rem">
        {item?.dealership_avatar ? (
          <Image
            src={item?.dealership_avatar}
            width={38}
            height={38}
            style={{
              borderRadius: "50%",
            }}
            alt="avatar"
          />
        ) : (
          <AccountCircleIcon />
        )}
        <VStack alignItems="flex-start" gap="0">
          <Text textStyle="h6" fontWeight="500">
            <TooltipIfOverflow char={80}>
              {item?.dealership_name}
            </TooltipIfOverflow>
          </Text>
          <Text textStyle="h6" fontWeight="500" color="var(--grey-700)">
            {item?.phone_number}
          </Text>
        </VStack>
      </HStack>
    ),
    receivedAt: moment(item?.received_at).format("MM/DD/YYYY hh:mm A zz"),
    receivedFrom: item?.received_from,
    totalCars: item?.total_cars,
    totalUsed: item?.used_cars,
    totalNew: item?.new_cars,
    //   actions: <Dropdown options={options} onSelect={handleSelect} />,
  }));

  return (
    <>
      <VStack
        w="100%"
        alignItems="flex-start"
        gap="0"
        overflow="auto"
        h="calc(100vh - 172px)"
      >
        <HStack
          w="100%"
          justifyContent="space-between"
          padding="1.25rem 1.5rem"
          background="white"
        >
          <HStack gap="0.625rem" w="100%" justifyContent="flex-start">
            <Input
              maxW="20rem"
              type="text"
              hasIcon={true}
              isIconRight={false}
              icon={<SearchIcon />}
              placeholder="Search Dealerships..."
              value={searchStr}
              onChange={({ target: { value } }) => setSearchStr(value)}
            />
          </HStack>
        </HStack>
        <VStack w="100%" alignItems="flex-start">
          <Box w="100%" alignItems="flex-start" background="white">
            <Text
              textStyle="caption"
              padding="1.5rem 1rem 0.75rem"
              w="100%"
              color="var(--grey-600)"
            >
              Total Dealerships ({data?.data?.response[0]?.count})
            </Text>
            <PaginatedTable
              header={activeHeader}
              data={activeData}
              itemsPerPage={perPage}
              isLoadingData={isLoading}
              maxPageNumbers={5}
              currentPage={page}
              tableHeight={420}
              isPaginated={true}
              lastCenter={false}
              hasMultiBody={false}
              setCurrentPage={setPage}
              perPageChange={(value) => {
                setPerPage(Number(value?.label));
                setPage(1);
              }}
              rowCount={count}
            />
          </Box>
        </VStack>
        {isLoading && <Loader />}
      </VStack>
    </>
  );
};

export default InventoryCompleted;
