import { Box } from "@chakra-ui/react";
import { useRef } from "react";
import { Controller } from "react-hook-form";
import AttachmentBar from "../../../components/AttachmentBar";
import { Input } from "../../../components/common";
import AttachmentViewer from "../../../components/common/AttachmentViewer";
import TextEditor from "../../../components/common/TextEditor";

export default function EmailContent({
  control,
  setValue,
  getValues,
  attachmentUrls,
  setAttachmentUrls,
}: any) {
  const editorRef = useRef();
  const handleSetBody = (
    text: string,
    concatText?: string,
    type = "text",
    isVariable = false
  ) => {
    const editor = (editorRef.current as any)?.current?.getEditor();
    const range = editor?.getSelection(true);
    if (isVariable) {
      editor.insertText(range.index, text, "user");
    } else if (type === "text") {
      editor.insertText(range.index, concatText, "user");
      editor.setSelection(range.index, concatText?.length || 0);
      editor.theme.tooltip.edit(
        "link",
        text.indexOf("://") === -1 ? "http://" + text : text
      );
      editor.theme.tooltip.save();
      editor.setSelection(range.index + concatText?.length || 0);
    } else if (type === "emoji") {
      if (range) {
        const cursorPosition = range.index;
        editor.insertText(cursorPosition, text);
        editor.setSelection(cursorPosition + text.length, 0);
      }
    } else if (type === "inline-attachment") {
      const url = (text as any).url;
      editor.insertEmbed(
        range.index,
        "image",
        url.indexOf("://") === -1 ? "http://" + url : url
      );
    } else {
      setAttachmentUrls((prev: any) => [...prev, text]);
    }
  };
  return (
    <>
      <Box mb="1rem">
        <Controller
          name="subject"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Input
              {...field}
              placeholder="Enter subject"
              label="Subject"
              error={error?.message || ""}
              maxLength={300}
              isRequired
            />
          )}
        />
      </Box>

      <Box mb="1rem">
        <Controller
          name="content"
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <div
                style={{
                  width: "100%",
                  height: "calc(100vh - 670px)",
                  overflow: "auto",
                  marginBottom: "24px",
                }}
                className="emailComposeWrapper"
              >
                <TextEditor
                  // onChange={onChange}
                  onChange={(value) => setValue("content", value)}
                  error={error?.message}
                  style={{
                    // height: "280px",
                    border: "1px solid #ccc",
                    overflow: "auto",
                  }}
                  editorRef={editorRef}
                  value={value}
                />
              </div>
            );
          }}
        />
        <AttachmentBar
          gap="0.5rem"
          width="3rem"
          flexWrap="wrap"
          setBody={handleSetBody}
          type="email"
          handleTemplate={(value, body = "", attachmentUrls) => {
            setValue("subject", value);
            setValue("content", body);
            setAttachmentUrls(attachmentUrls);
          }}
        />
        <AttachmentViewer
          attachments={attachmentUrls}
          setAttachments={setAttachmentUrls}
        />
      </Box>
    </>
  );
}
