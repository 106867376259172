import {
  Box,
  Button,
  HStack,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import AddIcon from "@mui/icons-material/Add";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import { useState } from "react";
import { fetchCoBuyers } from "../../../../../utils/api/co-buyer.api";
import { useUserInfo } from "../../../../../utils/hooks/useUserInfo";
import CreateNewLead from "../../../../Leads/CreateNewLead";
import { Spinner } from "../../../../common";

type cobuyer = {
  name: string;
  phone?: string;
};

const CoBuyer = (props: any) => {
  const { clickedRow } = props;
  const dealer = useUserInfo("dealership");

  const [count, setCount] = useState<number>(0);
  const [coBuyers, setCoBuyers] = useState<cobuyer[]>([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isFetching, refetch } = useQuery({
    queryKey: ["fetchCoBuyers", dealer?.id, clickedRow?.id],
    queryFn: fetchCoBuyers,
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess(data) {
      setCoBuyers(data?.data?.co_buyers);
      setCount(data?.data?.co_buyers_count);
    },
  });

  return (
    <Box h="100%" padding="1rem" background="var(--grey-50)">
      <VStack
        w="100%"
        h="100%"
        background="white"
        border="1px solid var(--grey-300)"
        borderRadius="0.5rem"
        alignItems="flex-start"
      >
        <HStack
          justifyContent="space-between"
          borderBottom="1px solid var(--grey-300)"
          padding="1rem"
          w="100%"
        >
          <HStack>
            <Text textStyle="h4" fontWeight="500">
              Co-Buyer
            </Text>
            {count && (
              <Text textStyle="h4" fontWeight="500" color="var(--grey-500)">
                ({count})
              </Text>
            )}
          </HStack>
          {count && (
            <Button
              variant="outline"
              padding="0.5rem"
              minW="auto"
              onClick={onOpen}
            >
              <AddIcon
                style={{
                  color: "var(--grey-700)",
                  fontSize: "1.5rem",
                }}
              />
            </Button>
          )}
        </HStack>
        {count ? (
          <VStack
            w="100%"
            padding="1rem"
            gap="1rem"
            h="calc(100vh - 220px)"
            overflow="auto"
          >
            {coBuyers?.map((item: any) => (
              <HStack
                w="100%"
                padding="1rem"
                border="1px solid var(--grey-300)"
                borderRadius="0.5rem"
                alignItems="flex-start"
                gap="0.5rem"
              >
                <HStack
                  gap="0.25rem"
                  alignItems="flex-start"
                  justifyContent="space-between"
                  width="100%"
                >
                  <VStack alignItems="flex-start">
                    <Text
                      textStyle="h5"
                      color="var(--grey-900)"
                      fontWeight="600"
                    >
                      {item?.full_name}
                    </Text>
                    <Text
                      textStyle="h5"
                      color="var(--grey-700)"
                      fontWeight="500"
                    >
                      {item?.phone_number}
                    </Text>
                  </VStack>
                  <VStack alignItems="flex-end">
                    <Text textStyle="h6" color="#1414c5" fontWeight="500">
                      {item?.created_by}
                    </Text>
                    <Text
                      textStyle="h6"
                      color="var(--grey-700)"
                      fontWeight="500"
                    >
                      {moment(item.created_at).format("MM-DD-YYYY hh:mm a")}
                    </Text>
                  </VStack>
                </HStack>
              </HStack>
            ))}
          </VStack>
        ) : (
          <VStack
            h="calc(100vh - 200px)"
            w="100%"
            justifyContent="center"
            padding="1rem"
          >
            <Text
              textStyle="h4"
              fontWeight="500"
              maxW="19rem"
              textAlign="center"
            >
              No co-buyers have been added to this customer!
            </Text>
            <Button onClick={onOpen}>Create Co-Buyer</Button>
          </VStack>
        )}
      </VStack>
      {isOpen && (
        <CreateNewLead
          isOpen={true}
          onClose={() => {
            onClose();
            refetch();
          }}
          contactId={coBuyers?.includes(clickedRow?.id) ? null : clickedRow?.id}
        />
      )}
      {isFetching && <Spinner />}
    </Box>
  );
};

export default CoBuyer;
