import { Box, Button, HStack, Text, VStack, useToast } from "@chakra-ui/react";
import DeleteIcon from "@mui/icons-material/Delete";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import fileDownload from "js-file-download";
import moment from "moment";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SERVICES } from "../../../config";
import { humanize, isImageLink } from "../../../utils";
import {
  deleteGlobalTemplate,
  duplicateGlobalTemplate,
  getGlobalTemplates,
} from "../../../utils/api/template.api";
import Popup from "../../Charts/Popup";
import { Dropdown, Input, PaginatedTable, Spinner } from "../../common";
import TooltipIfOverflow from "../../common/TooltipIfOverflow";
import {
  DuplicateIcon,
  EditIcon,
  ExcelIcon,
  PDFIcon,
  SearchIcon,
} from "../../icons/svg";

const options: SelectOptionProps[] = [
  {
    label: (
      <Box as="span" display="flex" alignItems="center" gap="0.5rem">
        <EditIcon color="var(--grey-800)" />
        Edit
      </Box>
    ),
    value: "edit",
  },
  {
    label: (
      <Box as="span" display="flex" alignItems="center" gap="0.5rem">
        <DuplicateIcon />
        Duplicate
      </Box>
    ),
    value: "duplicate",
  },
  {
    label: (
      <Box as="span" display="flex" alignItems="center" gap="0.5rem">
        <DeleteIcon style={{ color: "var(--grey-800)", width: "1.2rem" }} />
        Delete
      </Box>
    ),
    value: "delete",
  },
];

const ExportOptions: SelectOptionProps[] = [
  {
    label: (
      <Box as="span" display="flex" alignItems="center" gap="0.5rem">
        <ExcelIcon />
        Excel
      </Box>
    ),
    value: "xlsx",
  },
  {
    label: (
      <Box as="span" display="flex" alignItems="center" gap="0.5rem">
        <PDFIcon />
        PDF
      </Box>
    ),
    value: "pdf",
  },
];

const templateHeaders = [
  { id: 1, value: "Subject", label: "subject" },
  { id: 2, value: "Body", label: "body" },
  { label: "attachment_urls", value: "Attachment Urls" },
  { id: 4, value: "Date", label: "date" },
  { id: 4, value: "Type", label: "type" },
  { id: 5, value: "Actions", label: "actions" },
];

const GlobalEmailTemplates = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [searchStr, setSearchStr] = useState("");
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(20);

  const navigate = useNavigate();
  const toast = useToast();

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["fetchSMSTemplateGC", page, perPage, searchStr],
    queryFn: () => {
      const params: any = {
        page: page,
        per_page: perPage,
        searchStr: searchStr,
      };
      return getGlobalTemplates(params);
    },
    refetchOnWindowFocus: false,
    retry: false,
  });

  const mutation = useMutation<any, Error, any, unknown>({
    mutationFn: async (payload) => {
      try {
        const response = await duplicateGlobalTemplate(payload);
        return { data: response.data };
      } catch (error) {
        throw error;
      }
    },
    onSuccess: () => {
      toast({
        description: "Template Duplicated successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      refetch();
    },
    onError: (error: any) => {
      toast({
        description: `Error duplicating template: ${
          error?.response?.data?.errors?.toString() ?? "Something went wrong"
        }`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
  });

  const deleteMutation = useMutation<any, Error, any, unknown>({
    mutationFn: async (payload) => {
      try {
        const response = await deleteGlobalTemplate(payload);
        return { data: response.data };
      } catch (error) {
        throw error;
      }
    },
    onSuccess: () => {
      toast({
        description: "Template Deleted successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      setIsPopupOpen(false);
      refetch();
    },
    onError: (error: any) => {
      toast({
        description: `Error deleting template: ${
          error?.response?.data?.errors?.toString() ?? "Something went wrong"
        }`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
  });

  const handleSelect = async (option: SelectOptionProps, id: string) => {
    setSelectedOption(id);

    if (option.value === "edit") {
      navigate(`/template-compose?id=${id}&type=global`);
    }

    if (option.value === "duplicate") {
      await mutation.mutateAsync({ id });
    }

    if (option.value === "delete") {
      setIsPopupOpen(true);
    }
  };

  const tableData = useMemo(() => {
    if (!data?.data?.templates?.length) {
      return [];
    }

    return data?.data?.templates?.map((item: any) => {
      return {
        id: item.id,
        subject: item.subject,
        body: (
          <Box className="templateBody">
            <span dangerouslySetInnerHTML={{ __html: item.body }} />
          </Box>
        ),
        attachment_urls: (
          <>
            {item.attachment_urls?.map((attachment: any, index: number) => (
              <Text key={index} textStyle="h6" fontWeight="500">
                <TooltipIfOverflow>{`• ${attachment}`}</TooltipIfOverflow>
                {isImageLink(attachment) ? (
                  <img
                    src={attachment}
                    alt={attachment}
                    width={100}
                    style={{
                      whiteSpace: "nowrap",
                      width: "200px",
                      height: "150px",
                      marginTop: "12px",
                    }}
                  />
                ) : (
                  <></>
                )}
              </Text>
            ))}
          </>
        ),
        date: moment(item.created_at).format("MM-DD-YYYY hh:mm a"),
        type: humanize(item.template_type),
        actions: (
          <Dropdown
            options={options}
            onSelect={(value) => handleSelect(value, item.id)}
          />
        ),
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.data?.templates]);

  const handleExport = async (value: string) => {
    axios
      .get(`${SERVICES.apiBaseUrl}/global_templates.${value}`, {
        responseType: "blob",
      })
      .then((response) => {
        fileDownload(response.data, `Templates.${value}`);
      })
      .catch((response) => {});
  };

  return (
    <VStack
      w="100%"
      alignItems="flex-start"
      gap="0"
      overflow="auto"
      h="calc(100vh - 172px)"
    >
      <HStack
        w="100%"
        justifyContent="space-between"
        padding="1.25rem 1.5rem"
        background="white"
      >
        <HStack gap="0.625rem" w="100%" justifyContent="flex-start">
          <Input
            maxW="20rem"
            type="text"
            hasIcon={true}
            isIconRight={false}
            icon={<SearchIcon />}
            placeholder="Search Templates..."
            onChange={({ target: { value } }) => setSearchStr(value)}
            value={searchStr}
          />
        </HStack>
        <Button
          width={300}
          onClick={() => navigate(`/template-compose?type=global`)}
        >
          Create Template
        </Button>

        <Dropdown
          options={ExportOptions}
          hasText={true}
          text="Export"
          onSelect={(value: any) => handleExport(value.value)}
        />
      </HStack>
      <VStack w="100%" alignItems="flex-start" padding="1.5rem">
        <Box
          w="100%"
          alignItems="flex-start"
          background="white"
          border="1px solid var(--grey-300)"
          borderRadius="0.5rem"
        >
          <Text
            textStyle="caption"
            padding="1.5rem 1rem 0.75rem"
            w="100%"
            color="var(--grey-600)"
          >
            Total Templates ({data?.data?.total_count})
          </Text>
          <PaginatedTable
            header={templateHeaders}
            data={tableData}
            itemsPerPage={perPage}
            maxPageNumbers={3}
            isPaginated={true}
            lastCenter={false}
            hasMultiBody={false}
            perPageChange={(value) => {
              setPerPage(Number(value?.label));
            }}
            currentPage={page}
            setCurrentPage={setPage}
            rowCount={data?.data?.total_count}
          />
        </Box>
      </VStack>

      {(isLoading || mutation.isLoading || deleteMutation.isLoading) && (
        <Spinner />
      )}

      {isPopupOpen && (
        <Popup
          isOpen={isPopupOpen}
          onClose={() => setIsPopupOpen(false)}
          onConfirm={async () =>
            await deleteMutation.mutateAsync({
              id: selectedOption,
            })
          }
          title={"Delete Template"}
          message={"Are you sure you want to delete template?"}
          loading={deleteMutation.isLoading}
        />
      )}
    </VStack>
  );
};
export default GlobalEmailTemplates;
