import {
  HStack,
  Image,
  Text,
  VStack,
  Button,
  Box,
  useDisclosure,
} from "@chakra-ui/react";
import { Popover } from "../common";
import { LogoutIcon, SettingsIcon, NotificationBell } from "../icons/svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Logo from "../../assets/logo-black.png";
import { useNavigate, Navigate } from "react-router-dom";
import { logoutapi } from "../../utils/api/login.api";
import { logout, useAuthDispatch } from "../../utils/context/AuthContext";
import TooltipIfOverflow from "./TooltipIfOverflow";
import Notifications from "../Notifications/Notifications/Notifications";
import { fetchGlobalNotifications } from "../../utils/api/header-notifications.api";
import { getGlobalNotificationCount } from "../../utils/api/header-notifications.api";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useContext, useState } from "react";
import { ActionCableContext } from "../../App";
import { useUserInfo } from "../../utils/hooks/useUserInfo";
import ExpandableModal from "./ExpandableModal";
import { NotificationContext } from "../../utils/context/NotificationContext/NotificationContext";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

const BTNStyle = {
  height: "100%",
  width: "5rem",
  paddingLeft: "0.5rem",
  borderRight: "1px solid var(--grey-300)",
};

const GlobalHeader = () => {
  const { cable } = useContext(ActionCableContext);
  const navigate = useNavigate();
  const dispatch = useAuthDispatch();
  const [notiType, setNotiType] = useState<string>("");
  const [notiCount, setNotiCount] = useState<number>(0);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const {
    isOpen: notificationOpen,
    onOpen: onNotificationOpen,
    onClose: onNotificationClose,
  } = useDisclosure();
  const {
    onOpen: onModalOpen,
    isOpen: isModalOpen,
    onClose: onModalClose,
  } = useDisclosure();
  let user = localStorage.getItem("currentUser")
    ? JSON.parse(localStorage.getItem("currentUser")!)
    : "";
  const token = useUserInfo("token");
  const { data: count, refetch: countRefetch } = useQuery({
    queryKey: ["globalnotificationacount"],
    queryFn: getGlobalNotificationCount,
    refetchOnWindowFocus: false,
    retry: false,
  });
  const handleLogout = async () => {
    try {
      const logoutresponse = await logoutapi();
      if (logoutresponse) {
        logout(dispatch);
        <Navigate to="/login" />;
      }
    } catch (error) {
      logout(dispatch);
      <Navigate to="/login" />;
    }
  };
  const expandModalOpen = (val: string, count: number) => {
    setNotiType(val);
    setNotiCount(count);
    onModalOpen();
  };
  useEffect(() => {
    try {
      if (!cable) return;
      cable.subscriptions.create(
        {
          channel: "NotificationChannel",
          user_id: user?.id,
          token: token,
        },
        {
          connected: () => {
            console.log("Connected to Notification channel");
          },
          disconnected: () => {
            console.log("Disconnected from Notification channel");
          },
          received: async (data: any) => {
            countRefetch();
            console.log("Data received:", data);
          },
        }
      );
    } catch (error) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cable, token]);

  return (
    <>
      <NotificationContext.Provider
        value={{ refetchNotifications: countRefetch }}
      >
        <HStack
          w="100%"
          position="fixed"
          top="0"
          left="0"
          px="1.5rem"
          height="4.3rem"
          background="white"
          borderBottom="1px solid var(--grey-300)"
          justify="space-between"
          zIndex="10"
        >
          <Image
            src={Logo}
            alt="SimpSocial Logo"
            width="7.625rem"
            height="1.375rem"
          />
          <HStack h="100%" gap="0">
            <HStack h="100%" borderLeft="1px solid var(--grey-300)">
              <Popover
                btnVariant="none"
                variant="custom"
                btnStyle={BTNStyle}
                trigger={
                  <HStack onClick={onNotificationOpen}>
                    <NotificationBell />
                    <Text
                      textStyle="h5"
                      fontWeight="500"
                      color="var(--grey-900)"
                    >
                      {count?.data?.unread_notification_count}
                    </Text>
                  </HStack>
                }
                placement="bottom-start"
                isOpen={notificationOpen}
                onClose={onNotificationClose}
                contentStyle={{
                  width: "28rem",
                  borderRadius: "0.5rem",
                }}
              >
                {notificationOpen && (
                  <Notifications
                    onOpen={expandModalOpen}
                    count={count?.data?.total_notification_count}
                    onClose={onNotificationClose}
                    api={fetchGlobalNotifications}
                  />
                )}
              </Popover>
            </HStack>
            <Popover
              btnVariant="none"
              variant="custom"
              btnStyle={{ width: "max-content", paddingLeft: "1.4rem" }}
              trigger={
                <HStack position="relative" paddingRight="1.4rem" onClick={() => setPopoverOpen(!popoverOpen)}>
                  {user?.image_url ? (
                    <Image
                      src={user?.image_url}
                      style={{
                        width: "36px",
                        height: "36px",
                      }}
                      alt="avatar"
                    />
                  ) : (
                    <AccountCircleIcon  style={{
                      width: "36px",
                      height: "36px",
                    }} />
                  )}
                  <VStack gap="0" align="flex-start">
                    <Text
                      textStyle="h6"
                      fontWeight="700"
                      color="var(--grey-900)"
                    >
                      <TooltipIfOverflow>
                        {user?.first_name}{" "}
                        {user?.last_name ? user?.last_name : ""}
                      </TooltipIfOverflow>
                    </Text>
                    <Text
                      textStyle="h6"
                      fontWeight="500"
                      color="var(--grey-600)"
                    >
                      {user?.role ?? ""}
                    </Text>
                  </VStack>
                  <ExpandMoreIcon
                    style={{ position: "absolute", bottom: "-5px", right: "0" }}
                  />
                </HStack>
              }
              placement="bottom-start"
              contentStyle={{
                width: "16rem",
                borderRadius: "0.5rem",
              }}
              isOpen={popoverOpen} 
              onClose={() => setPopoverOpen(false)} 

            >
              <VStack
                w="100%"
                alignItems="flex-start"
                position="relative"
                gap="0"
              >
                <VStack w="100%" alignItems="flex-start" gap="0">
                  <VStack
                    w="100%"
                    gap="0.62rem"
                    padding="0.88rem"
                    borderBottom="1px solid var(--grey-300)"
                  >
                    {user?.image_url ? (
                      <Image
                        src={user?.image_url}
                        width="61px"
                        height="61px"
                        alt="avatar"
                      />
                    ) : (
                      <AccountCircleIcon 
                      style={{
                      width:"61px",
                        height:"61px"
                      }}/>
                    )}
                    <VStack w="100%" gap="0.19rem">
                      <Text textStyle="h5" fontWeight="600">
                        <TooltipIfOverflow>
                          {user?.first_name}{" "}
                          {user?.last_name ? user?.last_name : ""}
                        </TooltipIfOverflow>
                      </Text>
                      <Text
                        textStyle="h6"
                        color="var(--grey-700)"
                        fontWeight="500"
                      >
                        {user?.role ?? ""}
                      </Text>
                    </VStack>
                  </VStack>
                  <HStack
                    w="100%"
                    padding="0.5rem 0.96rem"
                    justifyContent="space-between"
                    onClick={handleLogout}
                    cursor="pointer"
                  >
                    <HStack gap="0.69rem">
                      <Box
                        padding="0.75rem"
                        border="1px solid var(--grey-300)"
                        borderRadius="50%"
                      >
                        <LogoutIcon />
                      </Box>
                      <Text textStyle="h6" fontWeight="500">
                        Log out
                      </Text>
                    </HStack>
                  </HStack>
                  <Button
                    variant="none"
                    position="absolute"
                    top="0.5rem"
                    right="0.5rem"
                    sx={{
                      svg: {
                        width: "1.5rem",
                        height: "1.5rem",
                        path: {
                          fill: "var(--grey-800)",
                        },
                      },
                    }}
                    onClick={() => {setPopoverOpen(false); navigate("/admin/settings")}}
                  >
                    <SettingsIcon />
                  </Button>
                </VStack>
              </VStack>
            </Popover>
          </HStack>
          {isModalOpen && (
            <ExpandableModal
              isOpen={isModalOpen}
              onClose={onModalClose}
              type={notiType}
              count={notiCount}
              api={fetchGlobalNotifications}
            />
          )}
        </HStack>
      </NotificationContext.Provider>
    </>
  );
};

export default GlobalHeader;
