"use client";
import { Box, Button, FormLabel, HStack, Text } from "@chakra-ui/react";
import { enGB } from "date-fns/locale";
import { useEffect, useState } from "react";
import { Calendar, DateRangePicker } from "react-date-range";

import Clear from "@mui/icons-material/Clear";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { Popover } from "../common";
import { ChevronDown } from "../icons/svg";

type DatePickerProps = {
  onChange?: ({
    startDate,
    endDate,
    selected,
    clear,
  }: {
    startDate: Date;
    endDate: Date;
    selected?: boolean;
    clear?: boolean;
  }) => void;
  value?: {
    startDate: Date;
    endDate: Date;
    selected?: boolean;
  };
  label?: string;
  isRequired?: boolean;
  maxW?: string;
  w?: string;
  isRange?: boolean;
  date?: Date;
  onDateChange?: (date: Date) => void;
  isDisabled?: boolean;
  shouldShowClear?: boolean;
  shouldShowLabel?: boolean;
  minDate?: Date;
  maxDate?: Date;
};

const DatePicker: React.FC<DatePickerProps> = ({
  onChange,
  value = { startDate: new Date(), endDate: new Date() },
  label,
  isRequired,
  maxW = "100%",
  w = "max-content",
  isRange = true,
  date = new Date(),
  onDateChange,
  isDisabled,
  shouldShowClear,
  shouldShowLabel = true,
  minDate,
  maxDate,
}) => {
  const [showPicker, setShowPicker] = useState(false);
  const [rangeValue, setRangeValue] = useState({
    startDate: "",
    endDate: new Date(),
  });

  useEffect(() => {
    setRangeValue(value as any);
  }, [value]);

  const handleSelect = (ranges: { [key: string]: any }) => {
    console.log("ranges", ranges)
    setRangeValue({ ...ranges.selection } as any);
  };

  console.log("rangeValue", rangeValue)

  const handleClear = (event: any) => {
    event.stopPropagation();
    if (!isRange) return onDateChange && onDateChange(undefined as any);
    onChange &&
      onChange({
        startDate: "" as any,
        endDate: new Date(""),
        selected: false,
        clear: true,
      });
  };

  const handleApply = () => {
    setShowPicker(false);
    onChange &&
      onChange({
        startDate: rangeValue.startDate as any,
        endDate: rangeValue.endDate,
        selected: true,
      });
  };

  const shouldShowMinDate = minDate ? { minDate } : {};

  return (
    <Box
      maxW={maxW}
      w={w}
      opacity={!isDisabled ? 1 : 0.5}
      pointerEvents={!isDisabled ? "auto" : "none"}
    >
      {shouldShowLabel && label && (
        <FormLabel
          mb="0.31rem"
          color="var(--grey-900)"
          fontSize="0.875rem"
          fontWeight="600"
          lineHeight="1rem"
        >
          {label}
          {isRequired && (
            <Box as="span" color="var(--red-600)">
              *
            </Box>
          )}
        </FormLabel>
      )}
      <Popover
        btnVariant="none"
        //   variant="custom"
        btnStyle={{ width: w }}
        trigger={
          <HStack
            border="1px solid var(--grey-300)"
            borderRadius="0.5rem"
            gap="0"
          >
            <Text
              textStyle="h6"
              fontWeight="500"
              padding="0.85rem 0.75rem"
              borderRight="1px solid var(--grey-300)"
              width={w}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <span>
                {isRange ? (
                  value?.startDate ? (
                    `${(value as any)?.startDate?.toDateString()}-${value?.endDate?.toDateString()}`
                  ) : (
                    <span style={{ color: "var(--grey-500)" }}>{label}</span>
                  )
                ) : date ? (
                  `${date.toDateString()}`
                ) : (
                  <span style={{ color: "var(--grey-500)" }}>{label}</span>
                )}
              </span>

              {shouldShowClear && (isRange ? value.startDate : date) && (
                <span onClick={handleClear} style={{ marginTop: 5 }}>
                  <Clear fontSize={"12px" as any} />
                </span>
              )}
            </Text>
            <Box padding="0.85rem 0.75rem">
              <ChevronDown color="var(--grey-900)" />
            </Box>
          </HStack>
        }
        placement="bottom-start"
        contentStyle={{
          width: w,
          borderRadius: "0.5rem",
        }}
        isOpen={showPicker}
        onOpen={() => setShowPicker(true)}
        onClose={() => setShowPicker(false)}
      >
        {isRange ? (
          <Box display="flex" flexDirection="column">
            <DateRangePicker
              ranges={[{ ...rangeValue, key: "selection" } as any]}
              months={2}
              direction="horizontal"
              preventSnapRefocus={true}
              calendarFocus="backwards"
              onChange={handleSelect}
              maxDate={maxDate}
            />
            <Button
              isDisabled={!rangeValue.startDate}
              onClick={() => handleApply()}
            >
              Apply
            </Button>
          </Box>
        ) : (
          <div style={{ display: "flex", flexFlow: "column nowrap" }}>
            <Calendar
              date={date}
              onChange={onDateChange}
              locale={enGB}
              maxDate={maxDate}
              {...shouldShowMinDate}
            />
          </div>
        )}
      </Popover>
    </Box>
  );
};
export default DatePicker;
