import {
  Box,
  Button,
  HStack,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import DeleteIcon from "@mui/icons-material/Delete";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { humanize, isImageLink } from "../../../utils";
import { fetchData } from "../../../utils/api/sms-templates.api";
import { Dropdown, PaginatedTable, Spinner } from "../../common";
import TooltipIfOverflow from "../../common/TooltipIfOverflow";
import { EditIcon } from "../../icons/svg";
import CreateSMSTemplate from "../../Reports/SMSTemplate/CreateSMSTemplate";
import DeleteSMSTemplate from "../../Reports/SMSTemplate/DeleteSMSTemplate";
import EditSMSTemplate from "../../Reports/SMSTemplate/EditSMSTemplate";

const SMSTemplateDealershipSettings = () => {
  const { id: dId } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedOption, setSelectedOption] =
    useState<SelectOptionProps | null>(null);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(20);

  const [templates, setTemplates] = useState([
    {
      id: 0,
      name: "",
      body: "",
      media_urls: [],
    },
  ]);

  const [clickedRow, setClickedRow] = useState({
    id: 0,
    name: "",
    body: "",
    media_urls: [],
  });

  const options: SelectOptionProps[] = [
    {
      label: (
        <Box as="span" display="flex" alignItems="center" gap="0.5rem">
          <EditIcon color="var(--grey-800)" />
          Edit
        </Box>
      ),
      value: "edit",
    },
    {
      label: (
        <Box as="span" display="flex" alignItems="center" gap="0.5rem">
          <DeleteIcon style={{ color: "var(--grey-800)", width: "1.2rem" }} />
          Delete
        </Box>
      ),
      value: "delete",
    },
  ];

  const handleSelect = (option: SelectOptionProps) => {
    setSelectedOption(option);
  };

  const tableHeader = [
    { value: "Name", label: "name" },
    { value: "body", label: "body" },
    { label: "media_urls", value: "Attachment Urls" },
    { value: "Type", label: "type" },
    { value: "Actions", label: "actions" },
  ];

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["fetchSMSTemplateDS", dId, page, perPage],
    queryFn: () => {
      const params: any = {
        page: page,
        per_page: perPage,
      };
      const endpoint = `/api/admin/dealerships/${dId}/sms_templates`;
      return fetchData(endpoint, params);
    },
    retry: false,
  });

  useEffect(() => {
    setTemplates(data?.sms_templates);
    setCount(data?.sms_templates_count ?? 0);
  }, [data]);

  const tableData = templates?.map((item: any) => ({
    id: item.id,
    name: item.name,
    body: item.body,
    type: humanize(item.type),
    media_urls: (
      <>
        {item.media_urls?.map((attachment: any, index: number) => (
          <Text key={index} textStyle="h6" fontWeight="500">
            <TooltipIfOverflow>
              {`• ${attachment?.filename}` ?? "-"}
            </TooltipIfOverflow>
            {isImageLink(attachment.url) ? (
              <img
                src={attachment.url}
                alt={attachment.url}
                width={100}
                style={{
                  whiteSpace: "nowrap",
                  width: "200px",
                  height: "150px",
                  marginTop: "12px",
                }}
              />
            ) : (
              <></>
            )}
          </Text>
        ))}
      </>
    ),
    actions: (
      <Dropdown
        options={options}
        onSelect={handleSelect}
        disabled={item.type === "simpsocial_provided"}
      />
    ),
  }));

  return (
    <Box>
      <VStack alignItems="flex-start" w="100%" padding="1.25rem 1rem 0">
        <HStack justifyContent="space-between" w="100%">
          <Text textStyle="h4" fontWeight="500">
            SMS Templates
          </Text>
          <Button onClick={onOpen}>Create Template</Button>
        </HStack>
        <Text textStyle="h6" color=" var(--grey-600)">
          Templates Found ({count})
        </Text>
      </VStack>
      <Box mt="1rem">
        <PaginatedTable
          header={tableHeader}
          data={tableData}
          itemsPerPage={perPage}
          maxPageNumbers={3}
          isPaginated={true}
          lastCenter={false}
          hasMultiBody={false}
          rowOnClick={(rowItem) => {
            setClickedRow(rowItem);
          }}
          perPageChange={(value) => {
            setPerPage(Number(value?.label));
          }}
          currentPage={page}
          setCurrentPage={setPage}
          rowCount={count}
        />
      </Box>
      {isOpen && (
        <CreateSMSTemplate
          isOpen={isOpen}
          onClose={onClose}
          refetchParent={refetch}
          endpoint={`/api/admin/dealerships/${dId}/sms_templates`}
          dId={dId}
          headers={{
            menu: "Dealerships",
            "Sub-Menu": "Settings/Dealership Settings/SMS Template",
            Dealership: dId,
          }}
        ></CreateSMSTemplate>
      )}

      {selectedOption?.value === "edit" && (
        <EditSMSTemplate
          isOpen={true}
          onClose={() => setSelectedOption(null)}
          refetchParent={refetch}
          id={clickedRow?.id}
          endpoint={`/api/admin/dealerships/${dId}/sms_templates/${clickedRow?.id}`}
          headers={{
            menu: "Dealerships",
            "Sub-Menu": "Settings/Dealership Settings/SMS Template",
            Dealership: dId,
          }}
        />
      )}
      {selectedOption?.value === "delete" && (
        <DeleteSMSTemplate
          isOpen={true}
          onClose={() => setSelectedOption(null)}
          refetchParent={refetch}
          id={clickedRow?.id}
          endpoint={`/api/admin/dealerships/${dId}/sms_templates/${clickedRow?.id}`}
          headers={{
            menu: "Dealerships",
            "Sub-Menu": "Settings/Dealership Settings/SMS Template",
            Dealership: dId,
          }}
        />
      )}
      {isLoading && <Spinner />}
    </Box>
  );
};

export default SMSTemplateDealershipSettings;
