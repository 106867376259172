import { Box, Button, HStack, Text, VStack, useToast } from "@chakra-ui/react";
import DeleteIcon from "@mui/icons-material/Delete";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import fileDownload from "js-file-download";
import moment from "moment";
import { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SERVICES } from "../../../config";
import { humanize, isImageLink } from "../../../utils";
import { getDealershipData } from "../../../utils/api/dealership-setting.api";
import {
  deleteTemplate,
  duplicateTemplate,
  getDealershipTemplates,
} from "../../../utils/api/template.api";
import Popup from "../../Charts/Popup";
import { Dropdown, Input, PaginatedTable } from "../../common";
import Loader from "../../common/Spinner";
import TooltipIfOverflow from "../../common/TooltipIfOverflow";
import {
  DuplicateIcon,
  EditIcon,
  ExcelIcon,
  PDFIcon,
  SearchIcon,
} from "../../icons/svg";

const ExportOptions: SelectOptionProps[] = [
  {
    label: (
      <Box as="span" display="flex" alignItems="center" gap="0.5rem">
        <ExcelIcon />
        Excel
      </Box>
    ),
    value: "xlsx",
  },
  {
    label: (
      <Box as="span" display="flex" alignItems="center" gap="0.5rem">
        <PDFIcon />
        PDF
      </Box>
    ),
    value: "pdf",
  },
];

const templateHeaders = [
  { id: 1, value: "Subject", label: "subject" },
  { id: 2, value: "Body", label: "body" },
  { label: "attachment_urls", value: "Attachment Urls" },
  { id: 4, value: "Date", label: "date" },
  { id: 4, value: "Type", label: "type" },
  { id: 5, value: "Actions", label: "actions" },
];

const options: SelectOptionProps[] = [
  {
    label: (
      <Box as="span" display="flex" alignItems="center" gap="0.5rem">
        <EditIcon color="var(--grey-800)" />
        Edit
      </Box>
    ),
    value: "edit",
  },
  {
    label: (
      <Box as="span" display="flex" alignItems="center" gap="0.5rem">
        <DuplicateIcon />
        Duplicate
      </Box>
    ),
    value: "duplicate",
  },
  {
    label: (
      <Box as="span" display="flex" alignItems="center" gap="0.5rem">
        <DeleteIcon style={{ color: "var(--grey-800)", width: "1.2rem" }} />
        Delete
      </Box>
    ),
    value: "delete",
  },
];

const DealershipTemplateListing = () => {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [searchStr, setSearchStr] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState<number>(10);

  const { id } = useParams();
  const toast = useToast();

  const { data: dealershipData } = useQuery({
    queryKey: ["dealershipData", id],
    queryFn: getDealershipData,
    refetchOnWindowFocus: false,
    retry: false,
  });

  console.log("dealershipData", dealershipData);

  const mutation = useMutation<any, Error, any, unknown>({
    mutationFn: async (payload) => {
      try {
        const response = await duplicateTemplate(payload);
        return { data: response.data };
      } catch (error) {
        throw error;
      }
    },
    onSuccess: () => {
      toast({
        description: "Template Duplicated successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      refetch();
    },
    onError: (error: any) => {
      toast({
        description: `Error duplicating template: ${
          error?.response?.data?.errors?.toString() ?? "Something went wrong"
        }`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
  });

  const deleteMutation = useMutation<any, Error, any, unknown>({
    mutationFn: async (payload) => {
      try {
        const response = await deleteTemplate(payload);
        return { data: response.data };
      } catch (error) {
        throw error;
      }
    },
    onSuccess: () => {
      toast({
        description: "Template Deleted successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      setIsPopupOpen(false);
      refetch();
    },
    onError: (error: any) => {
      toast({
        description: `Error deleting template: ${
          error?.response?.data?.errors?.toString() ?? "Something went wrong"
        }`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
  });

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["fetchTemplates", page, perPage, searchStr],
    queryFn: () =>
      getDealershipTemplates({
        type: "store_template",
        page,
        per_page: perPage,
        searchStr,
        dealership_id: id,
      }),
    retry: false,
  });

  const handleSelect = async (option: SelectOptionProps, itemId: string) => {
    setSelectedOption(itemId);

    if (option.value === "edit") {
      navigate(
        `/template-compose?id=${itemId}&dealership_id=${id}&type=dealership&uuid=${dealershipData?.data?.uuid}`
      );
    }

    if (option.value === "duplicate") {
      await mutation.mutateAsync({ id, dealership_id: itemId });
    }

    if (option.value === "delete") {
      setIsPopupOpen(true);
    }
  };

  const templateData = useMemo(() => {
    if (!data?.data?.templates?.length) {
      return [];
    }

    return data.data?.templates.map((item: any) => {
      return {
        subject: item.subject,
        body: (
          <Box className="templateBody">
            {" "}
            <span dangerouslySetInnerHTML={{ __html: item.body }} />
          </Box>
        ),
        attachment_urls: (
          <>
            {item.attachment_urls?.map((attachment: any, index: number) => (
              <Text key={index} textStyle="h6" fontWeight="500">
                <TooltipIfOverflow>{`• ${attachment}`}</TooltipIfOverflow>
                {isImageLink(attachment) ? (
                  <img
                    src={attachment}
                    alt={attachment}
                    width={100}
                    style={{
                      whiteSpace: "nowrap",
                      width: "200px",
                      height: "150px",
                      marginTop: "12px",
                    }}
                  />
                ) : (
                  <></>
                )}
              </Text>
            ))}
          </>
        ),
        date: moment(item.created_at).format("MM-DD-YYYY hh:mm a"),
        type:
          item.template_type === "store_template"
            ? "Dealership Provided"
            : humanize(item.template_type),
        actions:
          item.template_type === "store_template" ? (
            <Dropdown
              options={options}
              onSelect={(value) => handleSelect(value, item.id)}
            />
          ) : (
            <></>
          ),
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.data?.templates]);

  const handleExport = async (value: string) => {
    axios
      .get(
        `${SERVICES.apiBaseUrl}/api/dealerships/${id}/email_templates.${value}?template_type=store_template`,
        { responseType: "blob" }
      )
      .then((response) => {
        fileDownload(response.data, `Templates.${value}`);
      })
      .catch((response) => {});
  };

  return (
    <Box>
      {(mutation.isLoading || deleteMutation.isLoading) && <Loader />}
      <VStack
        w="100%"
        alignItems="flex-start"
        borderRadius="0.5rem"
        background="white"
      >
        <HStack
          gap="0.625rem"
          w="100%"
          justifyContent="space-between"
          padding="1rem 1rem 0rem"
        >
          <Input
            maxW="20rem"
            type="text"
            hasIcon={true}
            isIconRight={false}
            icon={<SearchIcon />}
            placeholder="Search Templates..."
            onChange={({ target: { value } }) => setSearchStr(value)}
            value={searchStr}
          />
          <Box display="flex" gap={5}>
            <Button
              variant="outlineBlue"
              color="var(--primary-default)"
              onClick={() =>
                navigate(
                  `/template-compose?dealership_id=${id}&type=dealership&uuid=${dealershipData?.data?.uuid}`
                )
              }
            >
              Create Template
            </Button>
            <Dropdown
              options={ExportOptions}
              hasText={true}
              text="Export"
              onSelect={(value: any) => handleExport(value.value)}
            />
          </Box>
        </HStack>
        <Text
          textStyle="caption"
          padding="1rem 1rem 0.75rem"
          w="100%"
          color="var(--grey-600)"
        >
          Total Templates ({data?.data?.total_count || 0})
        </Text>
        <PaginatedTable
          header={templateHeaders}
          data={templateData}
          itemsPerPage={perPage}
          maxPageNumbers={5}
          hasPadding={true}
          isPaginated={true}
          lastCenter={false}
          hasMultiBody={false}
          perPageChange={(value) => {
            setPerPage(Number(value?.label));
          }}
          currentPage={page}
          setCurrentPage={setPage}
          rowCount={data?.data?.total_count}
          isLoadingData={isLoading}
        />
      </VStack>

      {isPopupOpen && (
        <Popup
          isOpen={isPopupOpen}
          onClose={() => setIsPopupOpen(false)}
          onConfirm={async () =>
            await deleteMutation.mutateAsync({
              id: selectedOption,
              dealership_id: id,
            })
          }
          title={"Delete Template"}
          message={"Are you sure you want to delete template?"}
          loading={deleteMutation.isLoading}
        />
      )}
    </Box>
  );
};

export default DealershipTemplateListing;
