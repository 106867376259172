import { Box, Button, HStack, Text, useDisclosure } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Dropdown, PaginatedTable, Spinner } from "../../../components/common";
import { DuplicateIcon, EditIcon } from "../../../components/icons/svg";
import { fetchTemplateWithDealershipId } from "../../../utils/api/scorecard-settings.api";
import ActivateTemplate from "./ActivateTemplate/ActivateTemplate";
import CreateTemplate from "./CreateTemplate/CreateTemplate";
import DeactivateTemplate from "./DeactivateTemplate/DeactivateTemplate";
import DuplicateTemplate from "./DuplicateTemplate/DuplicateTemplate";
import EditTemplate from "./EditTemplate/EditTemplate";

const TranscriptionSettings = () => {
  const { id } = useParams();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(20);
  const [count, setCount] = useState<number>(0);
  const [clickedRow, setClickedRow] = useState<any>({
    id: 0,
    name: "",
  });
  const [activeModal, setActiveModal] = useState<string | null>(null);

  const handleSelect = (option: SelectOptionProps) => {
    setActiveModal(option.value);
  };
  const options: SelectOptionProps[] = [
    {
      label: (
        <Box as="span" display="flex" alignItems="center" gap="0.5rem">
          <EditIcon color="var(--grey-800)" />
          Edit
        </Box>
      ),
      value: "edit",
    },
    {
      label: (
        <Box as="span" display="flex" alignItems="center" gap="0.5rem">
          <DuplicateIcon />
          Duplicate
        </Box>
      ),
      value: "duplicate",
    },
    {
      label: (
        <Box as="span" display="flex" alignItems="center" gap="0.5rem">
          <DuplicateIcon />
          Activate
        </Box>
      ),
      value: "activate",
    },
  ];
  const optionsDeactivate: SelectOptionProps[] = [
    {
      label: (
        <Box as="span" display="flex" alignItems="center" gap="0.5rem">
          <EditIcon color="var(--grey-800)" />
          Edit
        </Box>
      ),
      value: "edit",
    },
    {
      label: (
        <Box as="span" display="flex" alignItems="center" gap="0.5rem">
          <DuplicateIcon />
          Duplicate
        </Box>
      ),
      value: "duplicate",
    },
    {
      label: (
        <Box as="span" display="flex" alignItems="center" gap="0.5rem">
          <DuplicateIcon />
          De-Activate
        </Box>
      ),
      value: "deactivate",
    },
  ];

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["fetchTranscriptionSettings", id, page, perPage],
    queryFn: () => fetchTemplateWithDealershipId(id, page, perPage),
  });

  const tableHeader = [
    { value: "Name of Template", label: "name" },
    { value: "Status", label: "status" },
    // { value: "Type", label: "type" },
    { value: "Actions", label: "actions" },
  ];
  const tableData = data
    ? data?.scorecards.map((template: any) => ({
        id: template.id,
        name: template.name || "-",
        category: (
          <Box
            w="4.6rem"
            textAlign="center"
            borderRadius="2.5rem"
            padding="0.25rem 0.62rem"
            border="1px solid var(--secondary-600)"
          >
            <Text textStyle="h6" color="var(--secondary-600)">
              {template.category === "sms"
                ? "SMS"
                : template.category === "calls"
                ? "Calls"
                : "-"}
            </Text>
          </Box>
        ),
        status: (
          <Box
            w="4.6rem"
            textAlign="center"
            borderRadius="2.5rem"
            padding="0.25rem 0.62rem"
            border="1px solid var(--secondary-600)"
            borderColor={
              template.status === "inactive" ? "red" : "var(--secondary-600)"
            }
          >
            <Text
              textStyle="h6"
              color={
                template.status === "inactive" ? "red" : "var(--secondary-600)"
              }
            >
              {template.status || "-"}
            </Text>
          </Box>
        ),
        actions: (
          <Dropdown
            options={
              template.type === "simpsocial_provided"
                ? [options[1]]
                : template.status === "inactive"
                ? options
                : optionsDeactivate
            }
            onSelect={handleSelect}
            disabled={template.type === "simpsocial_provided"}
          />
        ),
        type:
          template.type === "simpsocial_provided"
            ? "SimpSocial Provided"
            : "Dealership Provided",
      }))
    : [];

  useEffect(() => {
    setCount(data?.scorecards_count ?? 0);
  }, [data]);

  return (
    <Box>
      <HStack justifyContent="space-between" padding="1.25rem 1rem">
        <Text textStyle="h4" fontWeight="500">
          Scorecard Transcription Settings
        </Text>
        <Button onClick={onOpen}>Create New Scorecard</Button>
      </HStack>
      <Text textStyle="h6" color=" var(--grey-600)" padding="0rem 1rem 0.75rem">
        Total Templates ({count})
      </Text>
      <Box mt="1rem">
        <PaginatedTable
          header={tableHeader}
          data={tableData}
          itemsPerPage={perPage}
          maxPageNumbers={3}
          isPaginated={true}
          lastCenter={false}
          hasMultiBody={false}
          rowOnClick={(rowItem) => {
            setClickedRow(rowItem);
          }}
          perPageChange={(value) => {
            setPerPage(Number(value?.label));
          }}
          currentPage={page}
          setCurrentPage={setPage}
          rowCount={count}
        />
      </Box>
      {isOpen && (
        <CreateTemplate
          isOpen={isOpen}
          onClose={onClose}
          refetchParent={refetch}
        ></CreateTemplate>
      )}

      {activeModal === "edit" && (
        <EditTemplate
          isOpen={true}
          onClose={() => setActiveModal(null)}
          refetchParent={refetch}
          id={clickedRow?.id}
        />
      )}

      {activeModal === "duplicate" && (
        <DuplicateTemplate
          isOpen={true}
          onClose={() => setActiveModal(null)}
          refetchParent={refetch}
          id={clickedRow?.id}
        />
      )}

      {activeModal === "activate" && (
        <ActivateTemplate
          isOpen={true}
          onClose={() => setActiveModal(null)}
          refetchParent={refetch}
          id={clickedRow?.id}
        />
      )}
      {activeModal === "deactivate" && (
        <DeactivateTemplate
          isOpen={true}
          onClose={() => setActiveModal(null)}
          refetchParent={refetch}
          id={clickedRow?.id}
        />
      )}

      {isLoading && <Spinner />}
    </Box>
  );
};

export default TranscriptionSettings;
