import { Text, VStack } from "@chakra-ui/react";
import moment from "moment";
import { ContactCardIcon } from "../../../../../icons/svg";
import Activity from "../Activity/Activity";
import { humanize } from "../../../../../../utils";

type SingleContactCardProps = {
  item: any;
};

function SingleContactCard({ item }: SingleContactCardProps) {
  return (
    <Activity
      key={item.id}
      id={item.id}
      //   handleClick={() => setClickedRecord(item)}
      title={`Contact ${
        item.details?.hasOwnProperty("block")
          ? item?.details?.block
            ? "Blocked"
            : "Unblocked"
          : ""
      } ${
        item.details?.hasOwnProperty("dnc")
          ? item?.details?.block
            ? "DNC"
            : "DNC"
          : ""
      }`}
      dateAndTime={moment(item.created_at).format(
        "dddd MMM DD YY • hh:mm a"
      )}
      icon={<ContactCardIcon />}
      content={
        <>
          <VStack alignItems="flex-start" gap="0.25rem">
            {item.details?.hasOwnProperty("block") ||
            item.details?.hasOwnProperty("dnc") ? (
              <>
                {item.details?.hasOwnProperty("block") ? (
                  <Text color="var(--grey-800)" textStyle="h6" fontWeight="500">
                    Blocked By: {item?.details?.by}
                  </Text>
                ) : (
                  <Text color="var(--grey-800)" textStyle="h6" fontWeight="500">
                    DNC By: {item?.details?.by}
                  </Text>
                )}
              </>
            ) : (
              <>
                <Text color="var(--grey-800)" textStyle="h6" fontWeight="500">
                  First Name: {item?.details?.first_name}
                </Text>
                <Text color="var(--grey-800)" textStyle="h6" fontWeight="500">
                  Last Name: {item?.details?.last_name}
                </Text>
                <Text color="var(--grey-800)" textStyle="h6" fontWeight="500">
                  Phone Number: {item?.details?.phone_number}
                </Text>
              </>
            )}
          </VStack>
        </>
      }
    />
  );
}

export default SingleContactCard;
